import * as hash from 'object-hash';
import Common from './Common';
import { core, logger } from './helpers';
import OfferPriceService from './price/OfferPriceService';
import TranslateService from './translations/TranslateService';
import { PricePart } from './price/Prices';
import { PriceSegment } from './price/PriceSegment';
import { Profile, SideProfile } from 'configurations/parts/window';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import WindowConfiguration from 'configurations/WindowConfiguration';
import DoorConfiguration from 'configurations/DoorConfiguration';
import CoupledWindowConfiguration from 'configurations/CoupledWindowConfiguration';

export default class PositionsDetailsService {
    private mapMullionType = {
        fixed_mullion: 'static',
        false_mullion: 'movable',
        false_mullion_central: 'movableCentral',
        false_mullion_double: 'movableDouble',
    };

    private complementaryGoods = [
        'accessories',
        'cassonettos',
        'fillings',
        'profiles',
        'windowSills',
    ];

    constructor(
        private IccConfig,
        private positions,
        private offer,
        private access,
        private showPrices,
        private priceType,
        private translateFilter,
        private pdf = false,
        private translatedData = null,
        private showInfo: any = false,
        private allPositions = positions
    ) {
        'ngInject';
        if (Common.isArray(positions)) {
            return this.offerPositions(positions, access, translatedData, allPositions);
        } else {
            return this.offerPosition(positions, translatedData, access, allPositions);
        }
    }

    /**
     * Funkcja ustawiające dane do wyświetlania
     * @param  {array}  positions Pozycje
     * @param  {object} offer     Oferta
     * @param  {string} access    Typ użytkownika
     * @return {array}            Pozycje
     */
    offerPositions(positions, access, translatedData, allPositions) {
        for (let i = 0; i < positions.length; i++) {
            this.offerPosition(positions[i], translatedData, access, allPositions);
        }

        return positions;
    }

    offerPosition(position: any, translatedData: any, access: any, allPositions: any[]) {
        let pos;
        if (Common.isDefined(position.doc)) {
            pos = position.doc;
        } else {
            pos = position;
        }
        if (
            [
                'custom',
                'transport_cost',
                'colors_cost',
                'colors_waste_cost',
                'other',
                'additional',
            ].indexOf(pos.confType) === -1
        ) {
            if (!Common.isObject(pos.details)) {
                pos.details = core.parseJson(pos.details);
            }
            if (!Common.isObject(pos.configuration)) {
                pos.configuration = core.parseJson(pos.configuration);
            }
            const conf = pos.details;
            const drawData = pos.configuration.drawData;
            const prices =
                this.priceType === 'dealer' ? conf.priceSegmentsNoMargin : conf.priceSegments;
            const priceParts =
                this.priceType === 'dealer' ? conf.pricePartsNoMargin : conf.priceParts;
            let details = this.positionSimpleDetails(
                pos,
                conf,
                prices,
                priceParts,
                translatedData,
                allPositions
            );
            const positionDetailsData = {
                pos,
                conf,
                drawData,
                details,
                prices,
                priceParts,
                translatedData,
                allPositions,
            };
            if (conf.sashes && conf.sashes.length) {
                details = this.positionDetails(positionDetailsData);
                details = this.positionDetailsSashes(positionDetailsData);
            }
            if (Common.isDefined(conf.rollerShutter) && conf.rollerShutter !== null) {
                details = this.positionDetailsRollers(positionDetailsData);
            }
            if (
                conf.accessories
                && (conf.accessories.length
                    || (conf.sideAccessories
                        && (conf.sideAccessories.bottom.length
                            || conf.sideAccessories.top.length
                            || conf.sideAccessories.left.length
                            || conf.sideAccessories.right.length)))
            ) {
                details = this.positionDetailsAccessories(positionDetailsData);
            }
            if (conf.type === 'garage') {
                details = this.positionDetailsGarage(positionDetailsData);
            }
            if (conf.type === 'mosquito') {
                details = this.positionDetailsMosquito(positionDetailsData);
                if (
                    details.colors
                    && details.colors.frame
                    && !details.colors.frame.RAL
                    && details.colors.frame.group != null
                    && details.colors.frame.group !== 'RAL'
                ) {
                    details.noEdit = true;
                }
            }
            if (conf.type === 'winter_garden') {
                details = this.positionDetailsWinterGarden(positionDetailsData);
            }
            if (conf.type === 'awning') {
                details = this.positionDetailsAwning(positionDetailsData);
            }
            if (conf.type === 'complementary_goods') {
                details = this.positionDetailsComplementaryGoods(positionDetailsData);
            }
            if (
                details.mosquitos
                && details.mosquitos.length
                && details.mosquitos.some(
                    el =>
                        el.colors
                        && el.colors.frame
                        && !el.colors.frame.RAL
                        && el.colors.frame.group != null
                        && el.colors.frame.group !== 'RAL'
                )
            ) {
                details.noEdit = true;
            }
            if (conf.type === 'coupled_window') {
                details = this.positionDetailsCoupledWindow(positionDetailsData);
            }
            if (
                [
                    "window",
                    "hs",
                    "door",
                    "folding_door",
                    "sliding_door",
                    "garage_door",
                    "roller_shutter",
                    "pleated_blind",
                    "external_blind"
                ].indexOf(conf.type) > -1
                && this.offer.montages
            ) {
                details = this.positionMontages(positionDetailsData);
            }

            if (Common.isObject(pos.detailsForView)) {
                Object.assign(pos.detailsForView, details);
            } else {
                pos.detailsForView = details;
            }
        } else {
            if (!Common.isObject(pos.details)) {
                pos.details = core.parseJson(pos.details);
            }
            const conf = pos.details;
            let details = this.positionAdditionalDetails(pos, conf, access);
            if (pos.confType === 'colors_cost') {
                const positionDetailsData = {
                    conf,
                    details,
                    translatedData,
                    discounts: {
                        client: this.offer.client_discount_position,
                        dealer: this.offer.dealer_discount_producer,
                    },
                };
                details = this.positionDetailsColorsCost(positionDetailsData);
            }
            if (pos.confType === 'colors_waste_cost') {
                const positionDetailsData = {
                    conf,
                    details,
                    translatedData,
                };
                details = this.positionDetailsColorsWasteCost(positionDetailsData);
            }
            if (conf.goodType) {
                details.goodType = conf.goodType;
            }
            if (Common.isObject(pos.detailsForView)) {
                Object.assign(pos.detailsForView, details);
            } else {
                pos.detailsForView = details;
            }
        }
        return pos;
    }

    getPositionIndex(pos, allPositions) {
        let index = pos.listNumber;

        if (pos.coupled_position_id) {
            const coupledPosition = allPositions.find(
                el => el.doc.tmp_id === pos.coupled_position_id
            );
            if (coupledPosition
                && coupledPosition.doc
                && coupledPosition.doc.configuration
                && coupledPosition.doc.configuration.windows
            ) {
                const window = coupledPosition.doc.configuration.windows.find(
                    el => el.positionId === pos.tmp_id
                );
                if (window) {
                    index = coupledPosition.doc.listNumber + '.' + core.indexToLetter(window.index);
                }
            }
        }

        return index;
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} pos    Pozycja
     * @param  {object} conf   Konfiguracja
     * @param  {array}  prices Tablica segmentów cen
     * @return {object}        Dane pozycji dla widoku
     */
    positionSimpleDetails(pos, conf, prices, priceParts, translatedData, allPositions) {
        const posName = this.getPositionName(pos, translatedData);
        const index = this.getPositionIndex(pos, allPositions);
        return {
            id: pos.tmp_id,
            index,
            type: pos.confType,
            standard: pos.standard,
            name: posName,
            title: this.IccConfig.Offer.groupsOfPositions
                ? pos.custom_title
                    ? pos.custom_title
                    : posName
                : posName,
            customTitle: pos.custom_title,
            hasSashes: conf.sashes && conf.sashes.length ? true : false,
            height: conf.height,
            width: conf.width,
            size: pos.size || (conf.width + 'x' + conf.height),
            area: pos.area,
            glazing_area: pos.glazing_area,
            circuit: pos.circuit,
            image: pos.image,
            description: pos.description,
            groupCode: pos.groupCode,
            quantity: pos.quantity,
            attachments: pos.attachments,
            transportCost: pos.transport_cost,
            clientTransportCost: pos.client_transport_cost,
            weldFinishType: conf.weldFinishType,
            drainagePosition: conf.drainagePosition,
            timeLimit: conf.timeLimit,
            modified: pos.modified,
            issues: conf.issues || [],
            accessories: {
                price:
                    this.priceFromSegments(prices, priceParts, { value: { type: 'accessories' } })
                    + this.priceFromSegments(prices, priceParts, {
                        value: { type: 'sideAccessories' },
                    })
                    + this.priceFromSegments(prices, priceParts, {
                        value: { type: 'sashesHardware' },
                    })
                    + this.priceFromSegments(prices, priceParts, {
                        value: { type: 'complementaryGoods', data: { good: 'accessory' } },
                    }),
                construction: [],
                sashes: [],
                side: {
                    top: [],
                    bottom: [],
                    left: [],
                    right: [],
                },
            },
            dependencies: {
                price: this.priceFromSegments(prices, priceParts, {
                    value: { type: 'dependencies' },
                }),
                sashes: [],
            },
            price: conf.price,
            prices: {
                client: {
                    priceBeforeDiscount: Number(pos.client_price_before_discount),
                    price: Number(pos.client_price),
                    discount: Number(pos.client_discount),
                    base: core.roundPrice(pos.summary.client.components.base),
                    surcharges: core.roundPrice(
                        pos.summary.client.components.addons
                            + pos.summary.client.components.fitting
                            + pos.summary.client.components.glass
                            + pos.summary.client.components.roller
                            + pos.summary.client.components.colorCost
                            + pos.summary.client.components.mosquito
                    ),
                },
                dealer: {
                    priceBeforeDiscount: Number(pos.dealer_price_before_discount),
                    price: Number(pos.dealer_price),
                    discount: Number(pos.dealer_discount),
                    base: core.roundPrice(pos.summary.dealer.components.base),
                    surcharges: core.roundPrice(
                        pos.summary.dealer.components.addons
                            + pos.summary.dealer.components.fitting
                            + pos.summary.dealer.components.glass
                            + pos.summary.client.components.roller
                            + pos.summary.client.components.colorCost
                            + pos.summary.client.components.mosquito
                    ),
                },
                valuatedPrice: Number(pos.valuated_price),
                groupDiscounts: Common.isObject(pos.group_discounts)
                    ? pos.group_discounts
                    : core.parseJson(pos.group_discounts),
                noPrice: conf.issues && conf.issues.length
                    ? conf.issues.some(el => el.noPrice)
                    : false,
                show:
                    this.access
                    && ((pos.standard && (Number(pos.dealer_price) > 0 && this.showPrices))
                        || (!this.pdf
                            && ['2', '5', '6', '7', '8'].indexOf(this.offer.status) > -1
                            && this.access === 'producent')),
            },
        } as any;
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} pos    Pozycja
     * @param  {object} conf   Konfiguracja
     * @param  {array}  prices Tablica segmentów cen
     * @return {object}        Dane pozycji dla widoku
     */
    positionAdditionalDetails(pos, conf, access) {
        return {
            id: pos.tmp_id,
            index: pos.listNumber,
            type: pos.confType,
            standard: pos.standard,
            name: this.IccConfig.PDF.additionalPositionsLongName ? pos.details.name : pos.name,
            title: this.IccConfig.Offer.groupsOfPositions
                ? pos.custom_title
                    ? pos.custom_title
                    : pos.name
                : pos.name,
            customTitle: pos.custom_title,
            image: pos.image,
            description: pos.description,
            groupCode: pos.groupCode,
            quantity: pos.quantity,
            attachments: pos.attachments,
            modified: pos.modified,
            price: conf.price,
            prices: {
                client: {
                    priceBeforeDiscount: Number(pos.client_price_before_discount),
                    price: Number(pos.client_price),
                    discount: Number(pos.client_discount),
                    base: core.roundPrice(pos.summary.client.components.base),
                    surcharges: core.roundPrice(
                        pos.summary.client.components.addons
                            + pos.summary.client.components.fitting
                            + pos.summary.client.components.glass
                            + pos.summary.client.components.roller
                            + pos.summary.client.components.colorCost
                            + pos.summary.client.components.mosquito
                    ),
                },
                dealer: {
                    priceBeforeDiscount: Number(pos.dealer_price_before_discount),
                    price: Number(pos.dealer_price),
                    discount: Number(pos.dealer_discount),
                    base: core.roundPrice(pos.summary.dealer.components.base),
                    surcharges: core.roundPrice(
                        pos.summary.dealer.components.addons
                            + pos.summary.dealer.components.fitting
                            + pos.summary.dealer.components.glass
                            + pos.summary.client.components.roller
                            + pos.summary.client.components.colorCost
                            + pos.summary.client.components.mosquito
                    ),
                },
                valuatedPrice: Number(pos.valuated_price),
                groupDiscounts: Common.isObject(pos.group_discounts)
                    ? pos.group_discounts
                    : core.parseJson(pos.group_discounts),
                show:
                    this.access
                    && ((pos.standard && (Number(pos.dealer_price) > 0 && this.showPrices))
                        || (!this.pdf
                            && ['2', '5', '6', '7', '8'].indexOf(this.offer.status) > -1
                            && this.access === 'producent')),
            },
            goodType: null,
        };
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} details        Szczegóły pozycji
     * @param  {object} pos            Pozycja
     * @param  {object} conf           Konfiguracja
     * @param  {array}  prices         Tablica segmentów cen
     * @return {object}                Dane pozycji dla widoku
     */
    positionDetails({
        conf,
        drawData,
        details,
        prices,
        priceParts,
        translatedData,
    }: {
        conf: WindowConfiguration | DoorConfiguration;
        drawData: any;
        details: any;
        prices;
        priceParts;
        translatedData;
    }) {
        let profile;
        if (Common.isObject(conf.system)) {
            details.system = conf.system;
            details.system.name = TranslateService.translate(
                translatedData,
                details.system,
                'WindowLine',
                'name'
            );
            details.system.offerInfo = TranslateService.translate(
                translatedData,
                details.system,
                'WindowLine',
                'offer_info',
                'id',
                'offerInfo'
            );
            details.system.price = this.priceFromSegments(prices, priceParts, {
                percent: { type: 'system' },
            });
        }
        details.parameters = conf.parameters;
        details.attachments = conf.attachments;
        details.isMistakeProduct = conf.isMistakeProduct;
        details.hasWarranty = conf.hasWarranty;
        details.hasGlazingWarranty = conf.hasGlazingWarranty;
        details.doorSide =
            conf.type === 'door'
                ? conf.sashes.some(s => s.type && s.type.type === 'DOA')
                    ? 'Z'
                    : 'W'
                : null;

        if (this.IccConfig.PDF.showDoorSide) {
            const sidedSashes = conf.sashes
                .filter(sash => !['F', 'FF', 'K'].includes(sash.type && sash.type.type));

            if (sidedSashes.length <= 2) {
                const sides = sidedSashes
                    .map(sash => {
                        let openingSideLR = sash.type.handlePosition;
                        if (details.doorSide == 'W') {
                            openingSideLR = sash.type.handlePosition === 'R' ? 'L' : 'R';
                        }
                        return `/img/door/${details.doorSide}_${openingSideLR}.png`;
                    });
                const passiveIndex = sidedSashes.findIndex(sash => ['DOP', 'DRP'].includes(sash.type && sash.type.type));
                if (sidedSashes.length === 2 && passiveIndex > -1) {
                    details.doorOpeningSide = [`/img/door/${details.doorSide}_${passiveIndex}.png`];
                } else {
                    details.doorOpeningSide = Array.from(new Set(sides));
                }
            }
        }
        details.ownedSashesTypes = conf.ownedSashesTypes;
        details.balcony = conf.balcony;
        details.rollerShutter = null;
        details.handles = null;
        details.fillings = null;
        details.bondedGlazings = null;
        details.sashFrames = null;
        details.muntins = null;
        details.mosquitos = null;
        details.glazingBead = null;
        details.reinforcement = null;
        if (Common.isObject(conf.layout)) {
            details.layout = conf.layout;
            details.layout.name = TranslateService.translate(
                translatedData,
                details.layout,
                'SashesLayoutsVariant',
                'name'
            );
        }
        details.sashesTypes = {
            type: conf.sashesType,
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'sashType' } }),
            sashes: [],
            elems: [],
        };
        prices
            .filter(e => e != null && e.type === 'sashType')
            .forEach(e => {
                details.sashesTypes.elems.push({
                    name: TranslateService.translate(
                        translatedData,
                        { id: e.data.suppId, name: e.data.name },
                        'SashTypesSupplement',
                        'name'
                    ),
                    price: e.value,
                });
            });
        details.mullions = {
            isDefault: true,
            static: [],
            movable: [],
            movableCentral: [],
            movableDouble: [],
            astragalFrame: [],
            intMullions: [],
            staticOne: true,
            movableOne: true,
            movableCentralOne: true,
            movableDoubleOne: true,
            astragalFrameOne: true,
            intMullionsOne: true,
            price:
                this.priceFromSegments(prices, priceParts, { value: { type: 'dividers' } })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'intMullions' } }),
        };
        details.steel = !conf.steel
            ? null
            : {
                  type: conf.steel,
                  price: this.priceFromSegments(prices, priceParts, { percent: { type: 'steel' } }),
              };
        details.wood = !conf.wood
            ? null
            : {
                  id: conf.wood.id,
                  name: TranslateService.translate(translatedData, conf.wood, 'WoodType', 'name'),
                  price: this.priceFromSegments(prices, priceParts, { percent: { type: 'wood' } }),
              };
        details.alushell = {
            has: conf.hasAlushell,
            type: conf.alushellType,
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'alushell' } }),
        };
        details.sashes = {
            has: conf.sashes.length ? true : false,
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'sashes' } }),
            sashes: [],
        };
        details.shape = {
            shape: conf.shape.shape,
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'shape' } }),
            sashes: [],
        };
        const frameWithLowThreshold = conf.frames.find(f => f.lowThreshold);
        const lowThresholdProfile =
            frameWithLowThreshold
            && core.fIdO(conf.usedProfiles, frameWithLowThreshold.frame[0].profileId);
        details.lowThreshold = !frameWithLowThreshold
            ? false
            : {
                  id: frameWithLowThreshold.frame[0].id,
                  name: TranslateService.translate(
                      translatedData,
                      lowThresholdProfile,
                      'Profile',
                      'name'
                  ),
                  price: this.priceFromSegments(prices, priceParts, {
                      value: { type: 'lowThreshold' },
                  }),
              };
        details.kicker = {
            has: conf.kicker,
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'kicker' } }),
        };
        details.sealColor = !conf.sealColor
            ? null
            : {
                  id: conf.sealColor.id,
                  name: TranslateService.translate(
                      translatedData,
                      conf.sealColor,
                      'WindowSealColor',
                      'name'
                  ),
                  ral: conf.sealColor.RAL,
                  isDefault: conf.sealColor.isDefault,
                  price: this.priceFromSegments(prices, priceParts, {
                      percent: { type: 'sealColor' },
                  }),
              };
        details.siliconeColor = !conf.siliconeColor
            ? null
            : {
                  id: conf.siliconeColor.id,
                  name: TranslateService.translate(
                      translatedData,
                      conf.siliconeColor,
                      'WindowSiliconeColor',
                      'name'
                  ),
                  ral: conf.siliconeColor.RAL,
                  isDefault: conf.siliconeColor.isDefault,
                  price: this.priceFromSegments(prices, priceParts, {
                      percent: { type: 'siliconeColor' },
                  }),
              };
        details.hinge = !conf.hinge
            ? null
            : {
                  id: conf.hinge.id,
                  name: TranslateService.translate(
                      translatedData,
                      conf.hinge,
                      'WindowAccessory',
                      'name'
                  ),
                  priceSource: conf.hinge.priceSource,
                  price: this.priceFromSegments(prices, priceParts, { value: { type: 'hinge' } }),
                  color: {
                      id: conf.hinge.color.id,
                      name: TranslateService.translate(
                        translatedData,
                        conf.hinge.color,
                        'WindowHandlesColor',
                        'name'
                      ),
                      ral: conf.hinge.color.RAL,
                      isDefault: conf.hinge.color.isDefault,
                  },
              };
        details.lock = !conf.lock
            ? null
            : {
                  id: conf.lock.id,
                  name: TranslateService.translate(
                      translatedData,
                      conf.lock,
                      'WindowAccessory',
                      'name'
                  ),
                  price: this.priceFromSegments(prices, priceParts, { value: { type: 'lock' } }),
              };
        details.glazingSpacer = !conf.glazingSpacer
            ? null
            : {
                  id: conf.glazingSpacer.id,
                  u: conf.glazingSpacer.u,
                  name: TranslateService.translate(
                      translatedData,
                      conf.glazingSpacer,
                      'WindowWarmEdge',
                      'name'
                  ),
                  price: this.priceFromSegments(prices, priceParts, {
                      value: { type: 'warmEdge' },
                  }),
              };
        details.fitting = !conf.fitting
            ? null
            : {
                  id: conf.fitting.id,
                  name: TranslateService.translate(
                      translatedData,
                      conf.fitting,
                      'WindowFitting',
                      'name'
                  ),
                  price: this.priceFromSegments(prices, priceParts, {
                      value: { type: 'fittings' },
                  }),
              };
        details.profileSet = {
            isDefault: false,
        };
        if (conf.profileSet) {
            details.profileSet.isDefault = conf.profileSet.isDefault;
        }
        details.frames = {
            has: conf.frames.length ? true : false,
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'frame' } }),
            renoPrice: this.priceFromSegments(prices, priceParts, {
                value: { type: 'renoFrame' },
            }),
            isDefault: true,
            frames: [],
        };
        for (const frame of conf.frames) {
            if (frame.frame) {
                let frameDetails = null;
                for (let i = frame.frame.length - 1; i >= 0; i--) {
                    const el = frame.frame[i];
                    profile = core.fIdO(conf.usedProfiles, el.profileId);
                    if (frameDetails == null) {
                        frameDetails = {
                            id: frame.id,
                            profileId: el.profileId,
                            isDefault: el.isDefault,
                            frameIndex: core.indexToLetter(frame.index),
                            parameters: frame.parameters,
                            hasOneFinWidth: el.finWidth || 0,
                            hasOne: true,
                            name: TranslateService.translate(
                                translatedData,
                                profile,
                                'Profile',
                                'name'
                            ),
                            price: this.priceFromSegments(prices, priceParts, {
                                value: { type: 'frame', data: { frameId: frame.id } },
                            }),
                            renoPrice: this.priceFromSegments(prices, priceParts, {
                                value: { type: 'renoFrame', data: { frameId: frame.id } },
                            }),
                            monoblockDepth: profile.monoblockDepth ? profile.monoblockDepth : 0,
                            profiles: [],
                            weldFinishType: conf.weldFinishType,
                        };
                    }
                    frameDetails.profiles.push({
                        id: el.profileId,
                        isDefault: el.isDefault,
                        name: TranslateService.translate(
                            translatedData,
                            profile,
                            'Profile',
                            'name'
                        ),
                        monoblockDepth: profile.monoblockDepth ? profile.monoblockDepth : 0,
                        finWidth: el.finWidth,
                        side: el.side,
                        weldFinishType: conf.weldFinishType,
                    });

                    if (el.finWidth !== frameDetails.hasOneFinWidth) {
                        frameDetails.hasOneFinWidth = false;
                    }

                    details.frames.isDefault = details.frames && el.isDefault;

                    frameDetails.hasOne =
                        frameDetails.hasOne
                        && (frameDetails.profileId === el.profileId
                            || profile.type === 'threshold');
                }
                if (frameDetails) {
                    details.frames.frames.push(frameDetails);
                }
            }
        }

        details.couplings = {
            isDefault: true,
            couplings: [],
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'couplings' } }),
        };

        if (conf.couplings.length) {
            let topLeft;
            let bottomRight;
            let reinforcement;

            for (const coupling of conf.couplings) {
                topLeft = coupling.framesId.map(f => f.id)[0];
                bottomRight = coupling.otherFramesId.map(f => f.id)[0];
                topLeft = core.indexToLetter(
                    conf.frames[core.fId(conf.frames, topLeft, 'id')].index
                );
                bottomRight = core.indexToLetter(
                    conf.frames[core.fId(conf.frames, bottomRight, 'id')].index
                );

                profile = core.fIdO(conf.usedProfiles, coupling.profileId);

                reinforcement = coupling.reinforcement;
                if (reinforcement && reinforcement.id !== null) {
                    reinforcement.name = TranslateService.translate(
                        translatedData,
                        reinforcement,
                        'Reinforcement',
                        'name'
                    );
                }

                const couplingDetails = {
                    id: coupling.id,
                    profileId: coupling.profileId,
                    type: profile ? profile.type : null,
                    name: TranslateService.translate(translatedData, profile, 'Profile', 'name'),
                    outerWidth: coupling.widthOut,
                    innerWidth: coupling.width,
                    length: coupling.length,
                    direction: coupling.direction,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: { type: 'couplings', data: { couplingId: coupling.id } },
                    }),
                    topLeft,
                    bottomRight,
                    reinforcement,
                };
                details.couplings.couplings.push(couplingDetails);
            }
        }

        details.alignments = {
            alignments: [],
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'alignment' } }),
            show: false,
        };

        if (conf.alignments && conf.alignments.length > 0) {
            details.alignments.show = true;

            conf.alignments.map(alignment => {
                const sashes = [];

                ['top', 'left', 'right', 'bottom'].map(side => {
                    alignment.adjacentSashes[side]
                        .map(id => core.fIdO(conf.sashes, id))
                        .filter(sash => sash)
                        .forEach(sash => sashes.push(sash));
                });

                details.alignments.alignments.push({
                    id: alignment.id,
                    name: TranslateService.translate(
                        translatedData,
                        core.fIdO(conf.usedProfiles, alignment.profileId),
                        'Profile',
                        'name'
                    ),
                    price: this.priceFromSegments(prices, priceParts, {
                        value: { type: 'alignment', data: { id: alignment.id } },
                    }),
                    sash: sashes.length ? sashes[0].index : undefined,
                    side: alignment.side,
                    type: 'frame',
                });
            });
        }

        conf.sashes.map(sash => {
            if (sash.intAlignments && sash.intAlignments.length > 0) {
                details.alignments.show = true;

                sash.intAlignments.map(alignment => {
                    details.alignments.alignments.push({
                        id: alignment.id,
                        name: TranslateService.translate(
                            translatedData,
                            core.fIdO(conf.usedProfiles, alignment.profileId),
                            'Profile',
                            'name'
                        ),
                        price: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'alignment', data: { id: alignment.id } },
                        }),
                        sash: sash.index,
                        side: alignment.side,
                        type: 'sashFrame',
                    });
                });
            }
        });

        details.extensions = {
            extensions: [],
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'sideProfile' } }),
            show: false,
        };

        if (drawData && drawData.extension && conf.sideProfiles && conf.sideProfiles.length) {
            details.extensions.show = true;

            conf.sideProfiles.map(extension => {
                const extensionProfile = conf.usedProfiles.find(
                    usedProfile => usedProfile.id === extension.profileId
                );
                const extensionDimensions = drawData.extension.find(
                    el => el.extensionId === extension.id
                );

                details.extensions.extensions.push({
                    id: extension.id,
                    count: extension.count,
                    code: extensionProfile ? extensionProfile.code : null,
                    name: extensionProfile
                        ? TranslateService.translate(
                              translatedData,
                              extensionProfile,
                              'Profile',
                              'name'
                          )
                        : '',
                    type: extensionProfile ? extensionProfile.type : null,
                    reinforcement: extension.reinforcement
                        ? {
                            id: extension.reinforcement.id,
                            name: extension.reinforcement.name,
                          }
                        : null,
                    width: extensionDimensions.rect.width,
                    height: extensionDimensions.rect.height,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'sideProfile',
                            data: { id: extension.id, profileId: extension.profileId },
                        },
                    }),
                    side: extension.side,
                    color: {
                        core:
                            extension.color && extension.color.core && extension.color.core.id
                                ? {
                                      id: extension.color.core.id,
                                      RAL: extension.color.core.RAL,
                                      code: extension.color.core.code,
                                      type: extension.color.core.type,
                                      name: extension.color.core.RAL
                                          ? TranslateService.translate(
                                                translatedData,
                                                extension.color.core,
                                                'WindowColorRal',
                                                'name'
                                            )
                                          : TranslateService.translate(
                                                translatedData,
                                                extension.color.core,
                                                'WindowColor',
                                                'name'
                                            ),
                                  }
                                : null,
                        inner:
                            extension.color && extension.color.inner && extension.color.inner.id
                                ? {
                                      id: extension.color.inner.id,
                                      RAL: extension.color.inner.RAL,
                                      code: extension.color.inner.code,
                                      name: extension.color.inner.RAL
                                          ? TranslateService.translate(
                                                translatedData,
                                                extension.color.inner,
                                                'WindowColorRal',
                                                'name'
                                            )
                                          : TranslateService.translate(
                                                translatedData,
                                                extension.color.inner,
                                                'WindowColor',
                                                'name'
                                            ),
                                  }
                                : null,
                        outer:
                            extension.color && extension.color.outer && extension.color.outer.id
                                ? {
                                      id: extension.color.outer.id,
                                      RAL: extension.color.outer.RAL,
                                      code: extension.color.outer.code,
                                      name: extension.color.outer.RAL
                                          ? TranslateService.translate(
                                                translatedData,
                                                extension.color.outer,
                                                'WindowColorRal',
                                                'name'
                                            )
                                          : TranslateService.translate(
                                                translatedData,
                                                extension.color.outer,
                                                'WindowColor',
                                                'name'
                                            ),
                                  }
                                : null,
                    },
                });
            });
        }

        let colorHeaderType = '';
        let colorHeaderName = '';
        if (!conf.colorsSashExt) {
            if (conf.colorType === 'White') {
                colorHeaderType = this.translateFilter('COLOR|Kolor');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.colors.frame.core,
                    'WindowColor',
                    'name'
                );
            } else if (conf.colorType === 'Cream') {
                colorHeaderType = this.translateFilter('COLOR|Kolor');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.colors.frame.core,
                    'WindowColor',
                    'name'
                );
            } else if (conf.colorType === 'Outer') {
                colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny zewnętrzny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.colors.frame.outer,
                    'WindowColor',
                    'name'
                );
            } else if (conf.colorType === 'Inner') {
                colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny wewnętrzny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.colors.frame.inner,
                    'WindowColor',
                    'name'
                );
            } else if (conf.colorType === 'Bilateral') {
                colorHeaderType = this.translateFilter('COLOR|Kolor obustronny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.colors.frame.outer,
                    'WindowColor',
                    'name'
                );
            } else if (conf.colorType === 'Transparent') {
                colorHeaderType = this.translateFilter('COLOR|Lakierowanie transparentne');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.colors.frame.core,
                    'WindowColor',
                    'name'
                );
            } else if (conf.colorType === 'Opaque') {
                colorHeaderType = this.translateFilter('COLOR|Lakierowanie kryjące');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.colors.frame.core,
                    'WindowColor',
                    'name'
                );
            } else if (conf.colorType === '3D') {
                colorHeaderType = this.translateFilter('COLOR|Kolor 3D');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    conf.colors.frame.core,
                    'WindowColor',
                    'name'
                );
            }
        }
        if (conf.colorsSashExt || conf.colorType === 'Bicolor') {
            colorHeaderType = this.translateFilter('COLOR|Kolor');
            colorHeaderName = this.translateFilter('COLOR|różne');
        }
        const colorHeader = `${colorHeaderType}: ${colorHeaderName}`;
        details.colors = {
            header: colorHeader,
            type: conf.colorType,
            sashExt: conf.colorsSashExt,
            frame: !conf.colors.frame
                ? null
                : {
                      alushell: !conf.colors.frame.alushell
                          ? null
                          : {
                                RAL: conf.colors.frame.alushell.RAL,
                                code: conf.colors.frame.alushell.code,
                                isDefault: conf.colors.frame.alushell.isDefault,
                                id: conf.colors.frame.alushell.id,
                                name: conf.colors.frame.alushell.RAL
                                    ? TranslateService.translate(
                                          translatedData,
                                          conf.colors.frame.alushell,
                                          'WindowColorRal',
                                          'name'
                                      )
                                    : TranslateService.translate(
                                          translatedData,
                                          conf.colors.frame.alushell,
                                          'WindowColor',
                                          'name'
                                      ),
                            },
                      core: !conf.colors.frame.core
                          ? null
                          : {
                                RAL: conf.colors.frame.core.RAL,
                                code: conf.colors.frame.core.code,
                                isDefault: conf.colors.frame.core.isDefault,
                                id: conf.colors.frame.core.id,
                                name: conf.colors.frame.core.RAL
                                    ? TranslateService.translate(
                                          translatedData,
                                          conf.colors.frame.core,
                                          'WindowColorRal',
                                          'name'
                                      )
                                    : TranslateService.translate(
                                          translatedData,
                                          conf.colors.frame.core,
                                          'WindowColor',
                                          'name'
                                      ),
                            },
                      inner: !conf.colors.frame.inner
                          ? null
                          : {
                                RAL: conf.colors.frame.inner.RAL,
                                code: conf.colors.frame.inner.code,
                                isDefault: conf.colors.frame.inner.isDefault,
                                id: conf.colors.frame.inner.id,
                                name: conf.colors.frame.inner.RAL
                                    ? TranslateService.translate(
                                          translatedData,
                                          conf.colors.frame.inner,
                                          'WindowColorRal',
                                          'name'
                                      )
                                    : TranslateService.translate(
                                          translatedData,
                                          conf.colors.frame.inner,
                                          'WindowColor',
                                          'name'
                                      ),
                            },
                      outer: !conf.colors.frame.outer
                          ? null
                          : {
                                RAL: conf.colors.frame.outer.RAL,
                                code: conf.colors.frame.outer.code,
                                isDefault: conf.colors.frame.outer.isDefault,
                                id: conf.colors.frame.outer.id,
                                name: conf.colors.frame.outer.RAL
                                    ? TranslateService.translate(
                                          translatedData,
                                          conf.colors.frame.outer,
                                          'WindowColorRal',
                                          'name'
                                      )
                                    : TranslateService.translate(
                                          translatedData,
                                          conf.colors.frame.outer,
                                          'WindowColor',
                                          'name'
                                      ),
                            },
                  },
            sash: !conf.colors.sash
                ? null
                : {
                      alushell: !conf.colors.sash.alushell
                          ? null
                          : {
                                RAL: conf.colors.sash.alushell.RAL,
                                code: conf.colors.sash.alushell.code,
                                isDefault: conf.colors.sash.alushell.isDefault,
                                id: conf.colors.sash.alushell.id,
                                name: conf.colors.sash.alushell.RAL
                                    ? TranslateService.translate(
                                          translatedData,
                                          conf.colors.sash.alushell,
                                          'WindowColorRal',
                                          'name'
                                      )
                                    : TranslateService.translate(
                                          translatedData,
                                          conf.colors.sash.alushell,
                                          'WindowColor',
                                          'name'
                                      ),
                            },
                      core: !conf.colors.sash.core
                          ? null
                          : {
                                RAL: conf.colors.sash.core.RAL,
                                code: conf.colors.sash.core.code,
                                isDefault: conf.colors.sash.core.isDefault,
                                id: conf.colors.sash.core.id,
                                name: conf.colors.sash.core.RAL
                                    ? TranslateService.translate(
                                          translatedData,
                                          conf.colors.sash.core,
                                          'WindowColorRal',
                                          'name'
                                      )
                                    : TranslateService.translate(
                                          translatedData,
                                          conf.colors.sash.core,
                                          'WindowColor',
                                          'name'
                                      ),
                            },
                      inner: !conf.colors.sash.inner
                          ? null
                          : {
                                RAL: conf.colors.sash.inner.RAL,
                                code: conf.colors.sash.inner.code,
                                isDefault: conf.colors.sash.inner.isDefault,
                                id: conf.colors.sash.inner.id,
                                name: conf.colors.sash.inner.RAL
                                    ? TranslateService.translate(
                                          translatedData,
                                          conf.colors.sash.inner,
                                          'WindowColorRal',
                                          'name'
                                      )
                                    : TranslateService.translate(
                                          translatedData,
                                          conf.colors.sash.inner,
                                          'WindowColor',
                                          'name'
                                      ),
                            },
                      outer: !conf.colors.sash.outer
                          ? null
                          : {
                                RAL: conf.colors.sash.outer.RAL,
                                code: conf.colors.sash.outer.code,
                                isDefault: conf.colors.sash.outer.isDefault,
                                id: conf.colors.sash.outer.id,
                                name: conf.colors.sash.outer.RAL
                                    ? TranslateService.translate(
                                          translatedData,
                                          conf.colors.sash.outer,
                                          'WindowColorRal',
                                          'name'
                                      )
                                    : TranslateService.translate(
                                          translatedData,
                                          conf.colors.sash.outer,
                                          'WindowColor',
                                          'name'
                                      ),
                            },
                  },
            price:
                this.priceFromSegments(prices, priceParts, { percent: { type: 'color' } })
                + this.priceFromSegments(prices, priceParts, {
                    percent: { type: 'colorAlushell' },
                }),
        };

        if (DoorConfiguration.is(conf)) {
            if (Common.isDefined(conf.model) && conf.model !== null) {
                details.model = Common.extend(core.copy(conf.model), {
                    name: TranslateService.translate(
                        translatedData,
                        conf.model,
                        'DoorModel',
                        'name'
                    ),
                });
            }
            if (Common.isDefined(conf.modelGroup) && conf.modelGroup !== null) {
                details.modelGroup = Common.extend(core.copy(conf.modelGroup), {
                    name: TranslateService.translate(
                        translatedData,
                        conf.modelGroup,
                        'DoorModelsGroup',
                        'name'
                    ),
                });
            }
        }

        return details;
    }

    /**
     * Funkcja zwracająca cenę z segmentów
     * @param  {array}  prices  Tablica segmentów cen
     * @param  {string} type    Typ cen
     * @param  {number} sashId  Id skrzydła
     * @param  {number} isashId Id pola
     * @param  {number} divId   Id słupka
     * @return {number}         cena
     */
    priceFromSegments(
        prices: PriceSegment[] = [],
        priceParts: PricePart[] = [],
        {
            value,
            percent,
        }: {
            value?: {
                type: string;
                data?: any;
            };
            percent?: {
                type: string;
                data?: any;
            };
        }
    ) {
        const valueSegs = prices.filter(
            seg =>
                seg
                && (value && seg.type === value.type)
                && (!value
                    || !value.data
                    || Object.keys(value.data).every(key => {
                        return (
                            seg.data
                            && (core.deepFind(seg.data, key) === value.data[key]
                                || (Common.isObject(core.deepFind(seg.data, key))
                                    && Common.isObject(value.data[key])
                                    && hash(core.deepFind(seg.data, key))
                                        === hash(value.data[key])))
                        );
                    }))
        );
        const percentSegs = prices.filter(
            seg =>
                seg
                && (percent && seg.type === percent.type)
                && (!percent
                    || !percent.data
                    || Object.keys(percent.data).every(
                        key => seg.data && core.deepFind(seg.data, key) === percent.data[key]
                    ))
        );
        const parts = priceParts.filter(
            pp =>
                ((valueSegs.length > 0 && valueSegs.map(seg => seg.id).indexOf(pp.valueId) > -1)
                    || (!value || value.type === '*'))
                && ((percentSegs.length > 0
                    && percentSegs.map(seg => seg.id).indexOf(pp.percentId) > -1)
                    || (!percent || percent.type === '*')
                    || (percent.type === pp.percentType && percent.type === null))
        );
        return parts.reduce((prev, curr) => prev + curr.value, 0);
    }

    /**
     * Funkcja zwracająca dane z pól
     * @param  {object} conf      Konfiguracja pozycji
     * @param  {object} sash      Skrzydło
     * @param  {array}  intSashes Pola
     * @param  {array}  prices    Tablica segmentów cen
     * @return {object}           Dane z pól
     */
    positionDetailsIntSashes(conf, sash, intSashes, prices, priceParts, translatedData) {
        const fillingIntSashes = [];
        const bondedGlazingIntSashes = [];
        const glazingBeadIntSashes = [];
        const muntinsIntSashes = [];
        let muntinsIntSash;
        for (let i = 0; i < intSashes.length; i++) {
            fillingIntSashes.push({
                id: intSashes[i].filling.id,
                sashId: sash.id,
                sashIndex: sash.index,
                sashType: sash.type,
                isashId: intSashes[i].id,
                isashIndex: intSashes[i].index,
                type: intSashes[i].filling.type,
                u: intSashes[i].filling.u,
                rw: intSashes[i].filling.rw,
                c: intSashes[i].filling.c,
                ctr: intSashes[i].filling.ctr,
                lt: intSashes[i].filling.lt,
                g: intSashes[i].filling.g,
                psi: intSashes[i].filling.psi,
                weight: intSashes[i].filling.weight,
                name: TranslateService.translate(
                    translatedData,
                    intSashes[i].filling,
                    'Filling',
                    'name'
                ),
                producer: intSashes[i].filling.producer,
                hasOne: true,
                color: !intSashes[i].filling.color
                    ? null
                    : Common.extend(core.copy(intSashes[i].filling.color), {
                          inner: {
                              id: intSashes[i].filling.color.inner.id,
                              name: intSashes[i].filling.color.inner.RAL
                                  ? TranslateService.translate(
                                        translatedData,
                                        intSashes[i].filling.color.inner,
                                        'WindowColorRal',
                                        'name'
                                    )
                                  : TranslateService.translate(
                                        translatedData,
                                        intSashes[i].filling.color.inner,
                                        'WindowColor',
                                        'name'
                                    ),
                          },
                          outer: {
                              id: intSashes[i].filling.color.outer.id,
                              name: intSashes[i].filling.color.outer.RAL
                                  ? TranslateService.translate(
                                        translatedData,
                                        intSashes[i].filling.color.outer,
                                        'WindowColorRal',
                                        'name'
                                    )
                                  : TranslateService.translate(
                                        translatedData,
                                        intSashes[i].filling.color.outer,
                                        'WindowColor',
                                        'name'
                                    ),
                          },
                          core: {
                              id: intSashes[i].filling.color.core.id,
                              name: intSashes[i].filling.color.core.RAL
                                  ? TranslateService.translate(
                                        translatedData,
                                        intSashes[i].filling.color.core,
                                        'WindowColorRal',
                                        'name'
                                    )
                                  : TranslateService.translate(
                                        translatedData,
                                        intSashes[i].filling.color.core,
                                        'WindowColor',
                                        'name'
                                    ),
                          },
                      }),
                overlayColor: intSashes[i].filling.overlay_color,
                panelType: sash.panelType,
                panelThickness: intSashes[i].filling.thickness_mm || null,
                price:
                    this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'filling',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    })
                    + this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'fillingShape',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                priceGlazingSpacer: this.priceFromSegments(prices, priceParts, {
                    value: {
                        type: 'warmEdge',
                        data: { sashId: sash.id, isashId: intSashes[i].id },
                    },
                }),
                pricePanel: {
                    panel: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'panelPrice',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                    types: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'panelTypes',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                    sizes: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'panelSizes',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                    glazing: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'glazingPrice',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                    all:
                        this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'panelTypes',
                                data: { sashId: sash.id, isashId: intSashes[i].id },
                            },
                        })
                        + this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'panelSizes',
                                data: { sashId: sash.id, isashId: intSashes[i].id },
                            },
                        })
                        + this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'panelPrice',
                                data: { sashId: sash.id, isashId: intSashes[i].id },
                            },
                        })
                        + this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'glazingPrice',
                                data: { sashId: sash.id, isashId: intSashes[i].id },
                            },
                        }),
                },
                intSashes: [],
                fillingSizes: intSashes[i].fillingSizes,
                panelGlazing: !intSashes[i].panelGlazing
                    ? null
                    : {
                          id: intSashes[i].panelGlazing.id,
                          name: TranslateService.translate(
                              translatedData,
                              intSashes[i].panelGlazing,
                              'Filling',
                              'name'
                          ),
                          type: intSashes[i].panelGlazing.type,
                          producer: intSashes[i].panelGlazing.producer,
                      },
            });
            const glazingBead = core.fIdO<Profile>(
                conf.usedProfiles,
                intSashes[i].glazingBead.profileId
            );
            if (glazingBead) {
                let shape = false;
                if (Common.isDefined(glazingBead.profileShapeId)) {
                    shape = core.fIdO<any>(conf.usedProfileShapes, glazingBead.profileShapeId);
                }
                glazingBeadIntSashes.push({
                    id: glazingBead.id,
                    sashId: sash.id,
                    sashIndex: sash.index,
                    isashId: intSashes[i].id,
                    isashIndex: intSashes[i].index,
                    name: TranslateService.translate(
                        translatedData,
                        glazingBead,
                        'Profile',
                        'name'
                    ),
                    shape: shape
                        ? TranslateService.translate(translatedData, shape, 'ProfileShape', 'name')
                        : false,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'glazingBead',
                            data: { sashId: sash.id, isashId: intSashes[i].id },
                        },
                    }),
                });
            }

            bondedGlazingIntSashes.push({
                has: intSashes[i].bondedGlazing,
                sashId: sash.id,
                sashIndex: sash.index,
                isashId: intSashes[i].id,
                isashIndex: intSashes[i].index,
            });

            if (conf.muntins && intSashes[i].muntins && intSashes[i].muntins.length) {
                muntinsIntSash = {
                    sashId: sash.id,
                    sashIndex: sash.index,
                    isashId: intSashes[i].id,
                    isashIndex: intSashes[i].index,
                    horizontal: [],
                    vertical: [],
                };

                muntinsIntSash.horizontal = core.copy(
                    intSashes[i].muntins.filter(muntin => muntin.start.y === muntin.end.y)
                );
                muntinsIntSash.vertical = core.copy(
                    intSashes[i].muntins.filter(muntin => muntin.start.x === muntin.end.x)
                );

                muntinsIntSashes.push(muntinsIntSash);
            }
        }

        return { fillingIntSashes, muntinsIntSashes, glazingBeadIntSashes, bondedGlazingIntSashes };
    }

    /**
     * Funkcja zwracające dane z kwater
     * @param  {object} conf    Konfiguracja pozycji
     * @param  {object} details Dane do widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {array}          Dane do widoku
     */
    positionDetailsSashes({ conf, details, prices, priceParts, translatedData }) {
        const sashes = conf.sashes.sort((a, b) => a.index - b.index);
        let glazingCount;
        let intSashes;
        let div;
        let intSashesData;
        let fillingSash;
        let bondedGlazingsSash;
        let muntinsSash;
        let mosquitoSash;
        let glazingBeadSash;
        let sashAccessories;
        let topLeft;
        let bottomRight;
        let type;
        let profiles;
        let profile;
        let isDefault;
        let hasOne;
        let hasOneInSash;
        let reinforcement;

        const mullionsOne: {
            [prop: string]: any;
        } = {};

        details.measurements = conf.measurements;

        // Słupki początek
        if (conf.mullions.length) {
            for (let y = 0; y < conf.mullions.length; y++) {
                topLeft = conf.mullions[y].adjacentSashes.top
                    ? conf.mullions[y].adjacentSashes.top[0]
                    : conf.mullions[y].adjacentSashes.left[0];
                bottomRight = conf.mullions[y].adjacentSashes.bottom
                    ? conf.mullions[y].adjacentSashes.bottom[0]
                    : conf.mullions[y].adjacentSashes.right[0];
                topLeft = conf.sashes[core.fId(conf.sashes, topLeft, 'id')].index;
                bottomRight = conf.sashes[core.fId(conf.sashes, bottomRight, 'id')].index;

                profile = core.fIdO(conf.usedProfiles, conf.mullions[y].profileId);

                reinforcement = conf.mullions[y].reinforcement;
                if (reinforcement && reinforcement.id !== null) {
                    reinforcement.name = TranslateService.translate(
                        translatedData,
                        reinforcement,
                        'Reinforcement',
                        'name'
                    );
                }

                div = {
                    id: conf.mullions[y].id,
                    isDefault: conf.mullions[y].isDefault,
                    dividerId: conf.mullions[y].dividerId,
                    profileId: conf.mullions[y].profileId,
                    type: profile ? profile.type : null,
                    name: TranslateService.translate(translatedData, profile, 'Profile', 'name'),
                    outerWidth: conf.mullions[y].outerWidth,
                    innerWidth: conf.mullions[y].innerWidth,
                    length: conf.mullions[y].length,
                    position: conf.mullions[y].position,
                    direction: conf.mullions[y].direction,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: { type: 'dividers', data: { divId: conf.mullions[y].id } },
                    }),
                    topLeft,
                    bottomRight,
                    reinforcement,
                };
                details.mullions.isDefault =
                    details.mullions.isDefault
                    && (Common.isDefined(conf.mullions[y].isDefault)
                        ? conf.mullions[y].isDefault
                        : true);
                type = profile ? profile.type : null;
                if (profile && profile.options && profile.options.indexOf('central_handle') > -1) {
                    type = 'false_mullion_central';
                }
                if (profile && profile.options && profile.options.indexOf('double_handle') > -1) {
                    type = 'false_mullion_double';
                }
                if (profile && this.mapMullionType[type]) {
                    if (!mullionsOne[core.toCamelCase(this.mapMullionType[type])]) {
                        mullionsOne[core.toCamelCase(this.mapMullionType[type])] = div.profileId;
                    }
                    if (
                        div.profileId !== mullionsOne[core.toCamelCase(this.mapMullionType[type])]
                    ) {
                        details.mullions[
                            core.toCamelCase(this.mapMullionType[type]) + 'One'
                        ] = false;
                    }
                    details.mullions[core.toCamelCase(this.mapMullionType[type])].push(div);
                }
            }
        }
        // Słupki koniec

        for (let i = 0; i < sashes.length; i++) {
            // Skrzydła początek
            details.sashes.sashes.push({
                id: sashes[i].id,
                index: sashes[i].index,
                weight: sashes[i].weight,
                price: this.priceFromSegments(prices, priceParts, {
                    value: { type: 'sashes', data: { sashId: sashes[i].id, isashId: null } },
                }),
            });
            // Skrzydła koniec

            // Kształt początek
            details.shape.sashes.push({
                sashId: sashes[i].id,
                sashIndex: sashes[i].index,
                shape: sashes[i].shape.shape,
                price: this.priceFromSegments(prices, priceParts, {
                    value: { type: 'shape', data: { sashId: sashes[i].id, isashId: null } },
                }),
            });
            // Kształt koniec

            // Dane z pól
            if (sashes[i].intSashes.length) {
                intSashes = sashes[i].intSashes.sort((a, b) => a.index - b.index);
                intSashesData = this.positionDetailsIntSashes(
                    conf,
                    sashes[i],
                    intSashes,
                    prices,
                    priceParts,
                    translatedData
                );
            }

            // Szczebliny / szprosy konstrukcyjne początek
            if (sashes[i].intMullions.length) {
                for (let j = 0; j < sashes[i].intMullions.length; j++) {
                    topLeft = sashes[i].intMullions[j].adjacentSashes.top
                        ? sashes[i].intMullions[j].adjacentSashes.top[0]
                        : sashes[i].intMullions[j].adjacentSashes.left[0];
                    bottomRight = sashes[i].intMullions[j].adjacentSashes.bottom
                        ? sashes[i].intMullions[j].adjacentSashes.bottom[0]
                        : sashes[i].intMullions[j].adjacentSashes.right[0];
                    topLeft =
                        conf.sashes[i].intSashes[core.fId(conf.sashes[i].intSashes, topLeft, 'id')]
                            .index;
                    bottomRight =
                        conf.sashes[i].intSashes[
                            core.fId(conf.sashes[i].intSashes, bottomRight, 'id')
                        ].index;
                    profile = core.fIdO(conf.usedProfiles, sashes[i].intMullions[j].profileId);
                    details.mullions.intMullions.push({
                        id: sashes[i].intMullions[j].id,
                        isDefault: sashes[i].intMullions[j].isDefault,
                        dividerId: sashes[i].intMullions[j].dividerId,
                        profileId: sashes[i].intMullions[j].profileId,
                        sashId: sashes[i].id,
                        sashIndex: sashes[i].index,
                        type: sashes[i].intMullions[j].type,
                        name: TranslateService.translate(
                            translatedData,
                            profile,
                            'Profile',
                            'name'
                        ),
                        outerWidth: sashes[i].intMullions[j].outerWidth,
                        innerWidth: sashes[i].intMullions[j].innerWidth,
                        length: sashes[i].intMullions[j].length,
                        position: sashes[i].intMullions[j].position,
                        widthOuter: sashes[i].intMullions[j].widthOuter,
                        direction: sashes[i].intMullions[j].direction,
                        price: this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'intMullions',
                                data: { sashId: sashes[i].id, divId: sashes[i].intMullions[j].id },
                            },
                        }),
                        topLeft,
                        bottomRight,
                    });
                    details.mullions.isDefault =
                        details.mullions.isDefault
                        && (Common.isDefined(sashes[i].intMullions[j].isDefault)
                            ? sashes[i].intMullions[j].isDefault
                            : true);
                    if (!mullionsOne.intMullions) {
                        mullionsOne.intMullions = sashes[i].intMullions[j].profileId;
                    }
                    if (sashes[i].intMullions[j].profileId !== mullionsOne.intMullions) {
                        details.mullions.intMullionsOne = false;
                    }
                }
            }
            // Szczebliny / szprosy konstrukcyjne koniec

            // Akcesoria początek
            if (sashes[i].hardware.length) {
                sashAccessories = [];
                for (let j = 0; j < sashes[i].hardware.length; j++) {
                    const data = {
                        sashId: sashes[i].id,
                        id: sashes[i].hardware[j].id,
                    };
                    if (sashes[i].hardware[j].colorSet) {
                        data[`colorField`] = sashes[i].hardware[j].colorSet;
                    }
                    if (sashes[i].hardware[j].color && sashes[i].hardware[j].color.accessory) {
                        data[`colorId`] = sashes[i].hardware[j].color.accessory.id;
                        sashes[i].hardware[j].color.accessory.name = TranslateService.translate(
                            translatedData,
                            sashes[i].hardware[j].color.accessory,
                            'WindowHandlesColor',
                            'name'
                        );
                    }
                    sashAccessories.push(
                        Common.extend(core.copy(sashes[i].hardware[j]), {
                            name: TranslateService.translate(
                                translatedData,
                                sashes[i].hardware[j],
                                'WindowAccessory',
                                'name'
                            ),
                            price: this.priceFromSegments(prices, priceParts, {
                                value: { type: 'sashesHardware', data },
                            }),
                        })
                    );
                }
                details.accessories.sashes.push({
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: { type: 'sashesHardware', data: { sashId: sashes[i].id } },
                        percent: { type: 'sashesHardware' },
                    }),
                    accessories: sashAccessories,
                });
            }
            // Akcesoria koniec

            // Wzmocnienia konstrukcji początek
            if (sashes[i].reinforcement) {
                if (details.reinforcement === null) {
                    details.reinforcement = core.copy(sashes[i].reinforcement);
                    details.reinforcement.hasOne = conf.oneReinforcement;
                    details.reinforcement.show = false;
                    details.reinforcement.sashes = [];
                }
                details.reinforcement.sashes.push(sashes[i].reinforcement);
                if (sashes[i].reinforcement.showOnOffer) {
                    details.reinforcement.show = true;
                }
            }
            // Wzmocnienia konstrukcji koniec

            // Typy skrzydeł początek
            details.sashesTypes.sashes.push({
                id: sashes[i].type.id,
                type: sashes[i].type.type,
                name: TranslateService.translate(
                    translatedData,
                    sashes[i].type,
                    'SashType',
                    'name'
                ),
                handlePosition: sashes[i].type.handlePosition,
            });
            // Typy skrzydeł koniec

            let glazingBead = core.fIdO<Profile>(
                conf.usedProfiles,
                sashes[i].glazingBead.profileId
            );
            if (!glazingBead && sashes[i].intSashes.length) {
                glazingBead = core.fIdO<Profile>(
                    conf.usedProfiles,
                    sashes[i].intSashes[0].glazingBead.profileId
                );
            }
            if (glazingBead) {
                let shape = false;
                if (Common.isDefined(glazingBead.profileShapeId)) {
                    shape = core.fIdO<any>(conf.usedProfileShapes, glazingBead.profileShapeId);
                }
                // Listwy przyszybowe początek
                if (details.glazingBead === null) {
                    details.glazingBead = {
                        id: glazingBead.id,
                        sashId: null,
                        isashId: null,
                        name: TranslateService.translate(
                            translatedData,
                            glazingBead,
                            'Profile',
                            'name'
                        ),
                        shape: shape
                            ? TranslateService.translate(
                                  translatedData,
                                  shape,
                                  'ProfileShape',
                                  'name'
                              )
                            : false,
                        hasOne: true,
                        price: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'glazingBead' },
                        }),
                        sashes: [],
                        color: conf.glazingBeadColor && {
                            id: conf.glazingBeadColor.id,
                            name: TranslateService.translate(
                                translatedData,
                                conf.glazingBeadColor,
                                'GlazingBeadColor',
                                'name'
                            ),
                            ral: conf.glazingBeadColor.RAL,
                            isDefault: conf.glazingBeadColor.isDefault,
                            price: this.priceFromSegments(prices, priceParts, {
                                percent: { type: 'glazingBeadColor' },
                            }),
                        },
                    };
                }

                glazingBeadSash = {
                    id: glazingBead.id,
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    isashId: null,
                    name: TranslateService.translate(
                        translatedData,
                        glazingBead,
                        'Profile',
                        'name'
                    ),
                    shape: TranslateService.translate(
                        translatedData,
                        shape,
                        'ProfileShape',
                        'name'
                    ),
                    hasOne: true,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: { type: 'glazingBead' },
                    }),
                    intSashes: [],
                };

                if (details.glazingBead.id !== glazingBeadSash.id) {
                    details.glazingBead.hasOne = false;
                }

                if (sashes[i].intSashes.length) {
                    glazingBeadSash.intSashes = intSashesData.glazingBeadIntSashes;
                    for (let j = 0; j < glazingBeadSash.intSashes.length; j++) {
                        if (glazingBeadSash.id !== glazingBeadSash.intSashes[j].id) {
                            glazingBeadSash.hasOne = false;
                        }
                    }
                }
                details.glazingBead.hasOne = details.glazingBead.hasOne && glazingBeadSash.hasOne;
                if (glazingBeadSash.intSashes.length === 1) {
                    glazingBeadSash.name = glazingBeadSash.intSashes[0].name;
                    glazingBeadSash.shape = glazingBeadSash.intSashes[0].shape;
                    glazingBeadSash.price = glazingBeadSash.intSashes[0].price;
                    glazingBeadSash.hasOne = true;
                }
                details.glazingBead.sashes.push(glazingBeadSash);
                // Listwy przyszybowe koniec
            }
            // Wypełnienia początek
            glazingCount = prices.filter(
                e =>
                    e != null
                    && e.type === 'filling'
                    && Number(e.data.sashId) === Number(sashes[i].id)
                    && e.data.isashId === null
            );
            glazingCount = glazingCount.length ? glazingCount[0].data.glazingCount * 1 : null;
            if (details.fillings === null) {
                details.fillings = {
                    id: sashes[i].filling.id,
                    sashId: null,
                    isashId: null,
                    type: sashes[i].filling.type,
                    u: sashes[i].filling.u,
                    psi: sashes[i].filling.psi,
                    weight: sashes[i].filling.weight,
                    name: TranslateService.translate(
                        translatedData,
                        sashes[i].filling,
                        'Filling',
                        'name'
                    ),
                    producer: sashes[i].filling.producer,
                    hasOne:
                        (!this.IccConfig.Offer.fillingSizes && conf.oneGlazing)
                        || (this.IccConfig.Offer.fillingSizes
                            && sashes.length < 2
                            && (!sashes[0].intSashes || sashes[0].intSashes.length < 2)),
                    glazingCount,
                    price:
                        this.priceFromSegments(prices, priceParts, { value: { type: 'filling' } })
                        + this.priceFromSegments(prices, priceParts, {
                            value: { type: 'fillingShape' },
                        }),
                    pricePanel: {
                        panel: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'panelPrice' },
                        }),
                        types: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'panelTypes' },
                        }),
                        sizes: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'panelSizes' },
                        }),
                        glazing: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'glazingPrice' },
                        }),
                        all:
                            this.priceFromSegments(prices, priceParts, {
                                value: { type: 'panelTypes' },
                            })
                            + this.priceFromSegments(prices, priceParts, {
                                value: { type: 'panelSizes' },
                            })
                            + this.priceFromSegments(prices, priceParts, {
                                value: { type: 'panelPrice' },
                            })
                            + this.priceFromSegments(prices, priceParts, {
                                value: { type: 'glazingPrice' },
                            }),
                    },
                    sashes: [],
                    fillingSizes: sashes[i].fillingSizes,
                    color: null,
                    singleColor: false,
                    panelGlazing: !sashes[i].panelGlazing
                        ? null
                        : {
                              id: sashes[i].panelGlazing.id,
                              name: TranslateService.translate(
                                  translatedData,
                                  sashes[i].panelGlazing,
                                  'Filling',
                                  'name'
                              ),
                              type: sashes[i].panelGlazing.type,
                              producer: sashes[i].panelGlazing.producer,
                          },
                    isDecorative: false
                };
            }

            fillingSash = {
                id: sashes[i].filling.id,
                sashId: sashes[i].id,
                sashIndex: sashes[i].index,
                sashType: sashes[i].type,
                isashId: null,
                type: sashes[i].filling.type,
                u: sashes[i].filling.u,
                rw: sashes[i].filling.rw,
                c: sashes[i].filling.c,
                ctr: sashes[i].filling.ctr,
                lt: sashes[i].filling.lt,
                g: sashes[i].filling.g,
                psi: sashes[i].filling.psi,
                weight: sashes[i].filling.weight,
                color: !sashes[i].filling.color
                    ? null
                    : Common.extend(core.copy(sashes[i].filling.color), {
                          inner: {
                              id: sashes[i].filling.color.inner.id,
                              name: sashes[i].filling.color.inner.RAL
                                  ? TranslateService.translate(
                                        translatedData,
                                        sashes[i].filling.color.inner,
                                        'WindowColorRal',
                                        'name'
                                    )
                                  : TranslateService.translate(
                                        translatedData,
                                        sashes[i].filling.color.inner,
                                        'WindowColor',
                                        'name'
                                    ),
                          },
                          outer: {
                              id: sashes[i].filling.color.outer.id,
                              name: sashes[i].filling.color.outer.RAL
                                  ? TranslateService.translate(
                                        translatedData,
                                        sashes[i].filling.color.outer,
                                        'WindowColorRal',
                                        'name'
                                    )
                                  : TranslateService.translate(
                                        translatedData,
                                        sashes[i].filling.color.outer,
                                        'WindowColor',
                                        'name'
                                    ),
                          },
                          core: {
                              id: sashes[i].filling.color.core.id,
                              name: sashes[i].filling.color.core.RAL
                                  ? TranslateService.translate(
                                        translatedData,
                                        sashes[i].filling.color.core,
                                        'WindowColorRal',
                                        'name'
                                    )
                                  : TranslateService.translate(
                                        translatedData,
                                        sashes[i].filling.color.core,
                                        'WindowColor',
                                        'name'
                                    ),
                          },
                      }),
                overlayColor: sashes[i].filling.overlay_color,
                name: TranslateService.translate(
                    translatedData,
                    sashes[i].filling,
                    'Filling',
                    'name'
                ),
                producer: sashes[i].filling.producer,
                hasOne:
                    (!this.IccConfig.Offer.fillingSizes && sashes[i].oneGlazing)
                    || (this.IccConfig.Offer.fillingSizes
                        && (!sashes[i].intSashes || sashes[i].intSashes.length < 2)),
                singleColor: false,
                glazingCount,
                price:
                    this.priceFromSegments(prices, priceParts, {
                        value: { type: 'filling', data: { sashId: sashes[i].id } },
                    })
                    + this.priceFromSegments(prices, priceParts, {
                        value: { type: 'fillingShape', data: { sashId: sashes[i].id } },
                    }),
                priceGlazingSpacer: this.priceFromSegments(prices, priceParts, {
                    value: { type: 'warmEdge', data: { sashId: sashes[i].id } },
                }),
                pricePanel: {
                    panel: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'panelPrice',
                            data: { sashId: sashes[i].id, isashId: null },
                        },
                    }),
                    types: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'panelTypes',
                            data: { sashId: sashes[i].id, isashId: null },
                        },
                    }),
                    sizes: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'panelSizes',
                            data: { sashId: sashes[i].id, isashId: null },
                        },
                    }),
                    glazing: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'glazingPrice',
                            data: { sashId: sashes[i].id, isashId: null },
                        },
                    }),
                    all:
                        this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'panelTypes',
                                data: { sashId: sashes[i].id, isashId: null },
                            },
                        })
                        + this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'panelSizes',
                                data: { sashId: sashes[i].id, isashId: null },
                            },
                        })
                        + this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'panelPrice',
                                data: { sashId: sashes[i].id, isashId: null },
                            },
                        })
                        + this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'glazingPrice',
                                data: { sashId: sashes[i].id, isashId: null },
                            },
                        }),
                },
                intSashes: [],
                fillingSizes: sashes[i].fillingSizes,
                panelGlazing: !sashes[i].panelGlazing
                    ? null
                    : {
                          id: sashes[i].panelGlazing.id,
                          name: TranslateService.translate(
                              translatedData,
                              sashes[i].panelGlazing,
                              'Filling',
                              'name'
                          ),
                          type: sashes[i].panelGlazing.type,
                          producer: sashes[i].panelGlazing.producer,
                      },
            };
            if (fillingSash.color && fillingSash.color.inner.id === fillingSash.color.outer.id) {
                fillingSash.singleColor = true;
            }
            if (sashes[i].intSashes.length) {
                fillingSash.intSashes = intSashesData.fillingIntSashes;
                let fillingColor;
                fillingSash.intSashes.forEach(el => {
                    if (el.type === 'pvc_panels' || el.type === 'deco_panels') {
                        if (!fillingColor) {
                            fillingColor = el.color;
                            fillingSash.color = el.color;
                        }
                        fillingSash.hasOne =
                            fillingSash.hasOne
                            && el.hasOne
                            && (!this.IccConfig.Configurators.pvcPanelsDifferentColors
                                || (this.IccConfig.Configurators.pvcPanelsDifferentColors
                                    && el.color
                                    && el.color.inner.id === fillingColor.inner.id
                                    && el.color.outer.id === fillingColor.outer.id));
                        el.singleColor = el.color && el.color.inner.id === el.color.outer.id;
                        fillingSash.color = core.copy(el.color);
                        fillingSash.overlayColor = core.copy(el.overlayColor);
                        fillingSash.panelType = core.copy(el.panelType);
                        fillingSash.panelThickness = core.copy(el.panelThickness);
                    }
                    details.fillings.fillingSizes = el.fillingSizes;
                });
            }
            details.fillings.sashes.push(fillingSash);

            if (fillingSash.color) {
                if (!details.fillings.color) {
                    details.fillings.color = fillingSash.color;
                }
                details.fillings.hasOne =
                    details.fillings.hasOne
                    && fillingSash.hasOne
                    && details.fillings.color.inner.id === fillingSash.color.inner.id
                    && details.fillings.color.outer.id === fillingSash.color.outer.id;
            }

            details.fillings.isDecorative = details.fillings.isDecorative || fillingSash.type === 'deco_panels' || fillingSash.type === 'door_panels';

            if (details.bondedGlazings === null) {
                details.bondedGlazings = {
                    has: sashes.some(
                        sash =>
                            (sash.intSashes.length === 0 && sash.bondedGlazing)
                            || sash.intSashes.some(field => field.bondedGlazing)
                    ),
                    hasOne: sashes.every(
                        sash =>
                            (sash.intSashes.length === 0 && sash.bondedGlazing)
                            || sash.intSashes.every(field => field.bondedGlazing)
                    ),
                    sashes: [],
                };
            }

            bondedGlazingsSash = {
                has:
                    (sashes[i].intSashes.length === 0 && sashes[i].bondedGlazing)
                    || sashes[i].intSashes.some(field => field.bondedGlazing),
                hasOne:
                    (sashes[i].intSashes.length === 0 && sashes[i].bondedGlazing)
                    || sashes[i].intSashes.every(field => field.bondedGlazing),
                intSashes: [],
                sashId: sashes[i].id,
                sashIndex: sashes[i].index,
                isashId: null,
            };
            if (sashes[i].intSashes.length) {
                bondedGlazingsSash.intSashes = intSashesData.bondedGlazingIntSashes;
            }

            details.bondedGlazings.sashes.push(bondedGlazingsSash);
            // Wypełnienia koniec

            // Ramy skrzydeł początek
            if (sashes[i].type.type !== 'F') {
                profiles = {};
                isDefault = true;
                hasOne = true;
                hasOneInSash = true;
                reinforcement = {
                    id: null,
                    name: null,
                };

                for (const side in sashes[i].frame) {
                    profile = core.fIdO(conf.usedProfiles, sashes[i].frame[side].profileId);
                    reinforcement = sashes[i].frame[side].reinforcement;
                    if (reinforcement && reinforcement.id !== null) {
                        reinforcement.name = TranslateService.translate(
                            translatedData,
                            reinforcement,
                            'Reinforcement',
                            'name'
                        );
                    }

                    if (details.sashFrames === null) {
                        details.sashFrames = {
                            profileId: profile.id,
                            isDefault,
                            hasOne,
                            name: TranslateService.translate(
                                translatedData,
                                profile,
                                'Profile',
                                'name'
                            ),
                            price: this.priceFromSegments(prices, priceParts, {
                                value: { type: 'sashFrames' },
                            }),
                            sashes: [],
                            weldFinishType: conf.weldFinishType,
                            reinforcement,
                        };
                    }

                    profiles[side] = {
                        id: sashes[i].frame[side].profileId,
                        isDefault: sashes[i].frame[side].isDefault,
                        name: TranslateService.translate(
                            translatedData,
                            profile,
                            'Profile',
                            'name'
                        ),
                        reinforcement,
                    };

                    isDefault = isDefault && sashes[i].frame[side].isDefault;
                    hasOne =
                        hasOne
                        && sashes[i].frame[side].profileId === details.sashFrames.profileId
                        && (!details.sashFrames.reinforcement
                            || sashes[i].frame[side].reinforcementId
                                === details.sashFrames.reinforcement.id);
                    hasOneInSash =
                        hasOneInSash
                        && sashes[i].frame[side].profileId === sashes[i].frame.bottom.profileId
                        && sashes[i].frame[side].reinforcementId
                            === sashes[i].frame.bottom.reinforcementId;
                }

                details.sashFrames.sashes.push({
                    profileId: profile.id,
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    name: TranslateService.translate(translatedData, profile, 'Profile', 'name'),
                    price: this.priceFromSegments(prices, priceParts, {
                        value: { type: 'sashFrames', data: { sashId: sashes[i].id } },
                    }),
                    hasOne: hasOneInSash,
                    profiles,
                    isDefault,
                    weldFinishType: conf.weldFinishType,
                    reinforcement,
                });

                details.sashFrames.isDefault = details.sashFrames.isDefault && isDefault;
                details.sashFrames.hasOne = details.sashFrames.hasOne && hasOne;
            }
            // Ramy skrzydeł koniec

            // Klamki początek
            if (conf.hasHandle && (sashes[i].handleInner != null || sashes[i].handleOuter != null)) {
                let handleInnerDetails;
                if (sashes[i].handleInner != null) {
                    handleInnerDetails = {
                        id: sashes[i].handleInner.id,
                        handleType: sashes[i].handleInner.handleType,
                        name: TranslateService.translate(
                            translatedData,
                            sashes[i].handleInner,
                            'WindowAccessory',
                            'name'
                        ),
                        color: {
                            id: sashes[i].handleInner.color.id,
                            name: TranslateService.translate(
                                translatedData,
                                sashes[i].handleInner.color,
                                'WindowHandlesColor',
                                'name'
                            ),
                            isDefault: sashes[i].handleInner.color.isDefault,
                        },
                    };
                }

                let handleOuterDetails;
                if (sashes[i].handleOuter != null) {
                    handleOuterDetails = {
                        id: sashes[i].handleOuter.id,
                        handleType: sashes[i].handleOuter.handleType,
                        name: TranslateService.translate(
                            translatedData,
                            sashes[i].handleOuter,
                            'WindowAccessory',
                            'name'
                        ),
                        color: {
                            id: sashes[i].handleOuter.color.id,
                            name: TranslateService.translate(
                                translatedData,
                                sashes[i].handleOuter.color,
                                'WindowHandlesColor',
                                'name'
                            ),
                            isDefault: sashes[i].handleOuter.color.isDefault,
                        },
                    };
                }

                if (details.handles === null) {
                    details.handles = {
                        sashId: null,
                        hasOne: conf.oneHandle,
                        oneHeight: conf.oneHandlesHeight,
                        height:
                            sashes[i].type.handlePosition !== 'T' ? sashes[i].handleHeight : null,
                        type: conf.handleType,
                        inner: handleInnerDetails,
                        outer: handleOuterDetails,
                        price: {
                            handle: this.priceFromSegments(prices, priceParts, {
                                value: { type: 'handles' },
                            }),
                            handleHeight: this.priceFromSegments(prices, priceParts, {
                                value: { type: 'handlesHeights' },
                            }),
                            handlePosition: this.priceFromSegments(prices, priceParts, {
                                value: { type: 'handlePosition' },
                            }),
                            all:
                                this.priceFromSegments(prices, priceParts, {
                                    value: { type: 'handles' },
                                })
                                + this.priceFromSegments(prices, priceParts, {
                                    value: { type: 'handlesHeights' },
                                })
                                + this.priceFromSegments(prices, priceParts, {
                                    value: { type: 'handlePosition' },
                                }),
                        },
                        sashes: [],
                    };
                }

                details.handles.sashes.push({
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    isDefaultHeight: sashes[i].defaultHandleHeight,
                    height: sashes[i].type.handlePosition !== 'T' ? sashes[i].handleHeight : null,
                    type: conf.handleType,
                    inner: handleInnerDetails,
                    outer: handleOuterDetails,
                    price: {
                        handle: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'handles', data: { sashId: sashes[i].id } },
                        }),
                        handleHeight: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'handlesHeights', data: { sashId: sashes[i].id } },
                        }),
                        handlePosition: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'handlePosition', data: { sashId: sashes[i].id } },
                        }),
                        all:
                            this.priceFromSegments(prices, priceParts, {
                                value: { type: 'handles', data: { sashId: sashes[i].id } },
                            })
                            + this.priceFromSegments(prices, priceParts, {
                                value: { type: 'handlesHeights', data: { sashId: sashes[i].id } },
                            })
                            + this.priceFromSegments(prices, priceParts, {
                                value: { type: 'handlePosition', data: { sashId: sashes[i].id } },
                            }),
                    },
                });
            }
            // Klamki koniec

            // Szprosy początek
            if (conf.muntins) {
                muntinsSash = {
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    horizontal: [],
                    vertical: [],
                    intSashes: [],
                    price: this.priceFromSegments(prices, priceParts, {
                        value: { type: 'muntins', data: { sashId: sashes[i].id } },
                    }),
                };

                if (details.muntins === null) {
                    details.muntins = {
                        size: conf.muntins.size,
                        duplex: conf.muntins.duplex,
                        price: this.priceFromSegments(prices, priceParts, {
                            value: { type: 'muntins' },
                        }),
                        colorDraw: conf.muntins.colorDraw,
                        colorInner: conf.muntins.colorInner
                            ? {
                                  id: conf.muntins.colorInner.id,
                                  name: conf.muntins.colorInner.RAL
                                      ? TranslateService.translate(
                                            translatedData,
                                            conf.muntins.colorInner,
                                            'WindowColorRal',
                                            'name'
                                        )
                                      : TranslateService.translate(
                                            translatedData,
                                            conf.muntins.colorInner,
                                            'WindowColor',
                                            'name'
                                        ),
                                  code: conf.muntins.colorInner.code,
                                  ral: conf.muntins.colorInner.RAL,
                                  isDefault: conf.muntins.colorInner.isDefault,
                                  type: conf.muntins.colorInner.type,
                              }
                            : null,
                        colorOuter: conf.muntins.colorOuter
                            ? {
                                  id: conf.muntins.colorOuter.id,
                                  name: conf.muntins.colorOuter.RAL
                                      ? TranslateService.translate(
                                            translatedData,
                                            conf.muntins.colorOuter,
                                            'WindowColorRal',
                                            'name'
                                        )
                                      : TranslateService.translate(
                                            translatedData,
                                            conf.muntins.colorOuter,
                                            'WindowColor',
                                            'name'
                                        ),
                                  code: conf.muntins.colorOuter.code,
                                  ral: conf.muntins.colorOuter.RAL,
                                  isDefault: conf.muntins.colorOuter.isDefault,
                                  type: conf.muntins.colorOuter.type,
                              }
                            : null,
                        type: {
                            id: conf.muntins.type.type.id,
                            type: conf.muntins.type.type.type,
                            name: TranslateService.translate(
                                translatedData,
                                conf.muntins.type.type,
                                'WindowBarType',
                                'name'
                            ),
                            duplex: conf.muntins.type.type.duplex,
                        },
                        sashes: [],
                    };
                }

                if (sashes[i].muntins && sashes[i].muntins.length) {
                    muntinsSash.horizontal = core.copy(
                        sashes[i].muntins.filter(muntin => muntin.start.y === muntin.end.y)
                    );
                    muntinsSash.vertical = core.copy(
                        sashes[i].muntins.filter(muntin => muntin.start.x === muntin.end.x)
                    );
                }

                if (
                    sashes[i].intSashes.length
                    && Common.isDefined(intSashesData.muntinsIntSashes)
                ) {
                    muntinsSash.intSashes = intSashesData.muntinsIntSashes;
                }
                details.muntins.sashes.push(muntinsSash);
            }
            // Szprosy koniec

            // siatki - w konstrukcji i w kwaterach
            const mosquitos = [
                conf.mosquito,
                ...conf.sashes.map(s => ({ ...s.mosquito, sashId: s.id })),
            ].filter(e => e && e.system);
            if (mosquitos.length) {
                details.mosquitos = {
                    price:
                        this.priceFromSegments(prices, priceParts, {
                            value: { type: 'mosquitoSize' },
                        })
                        + this.priceFromSegments(prices, priceParts, {
                            value: { type: 'mosquitoColor' },
                        })
                        + this.priceFromSegments(prices, priceParts, {
                            value: { type: 'mosquitoMontage' },
                        })
                        + this.priceFromSegments(prices, priceParts, {
                            value: { type: 'mosquitoProfile' },
                        }),
                    mosquitos: [],
                };
                mosquitos.map((mosquito, index) => {
                    details.mosquitos.mosquitos[index] = {};
                    this.positionDetailsMosquito({
                        conf: mosquito,
                        details: details.mosquitos.mosquitos[index],
                        prices,
                        priceParts,
                        translatedData,
                    });
                });
            }

            if (
                this.priceFromSegments(prices, priceParts, {
                    value: { type: 'sashDependencies', data: { sashId: sashes[i].id } },
                }) > 0
            ) {
                details.dependencies.sashes.push({
                    sashId: sashes[i].id,
                    sashIndex: sashes[i].index,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: { type: 'sashDependencies', data: { sashId: sashes[i].id } },
                    }),
                });
            }
        }

        return details;
    }

    /**
     * Funkcja zwracająca dane rolet
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsRollers({ conf, details, prices, priceParts, translatedData }) {
        details.rollerShutter = core.copy(conf.rollerShutter);
        details.parameters = conf.parameters;
        if (
            Common.isDefined(details.rollerShutter.colors.box)
            && details.rollerShutter.colors.box
        ) {
            details.rollerShutter.colors.box.name = details.rollerShutter.colors.box.RAL
                ? TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.box,
                      'WindowColorRal',
                      'name'
                  )
                : TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.box,
                      'WindowColor',
                      'name'
                  );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.boxGuideOuter)
            && details.rollerShutter.colors.boxGuideOuter
        ) {
            details.rollerShutter.colors.boxGuideOuter.name = details.rollerShutter.colors
                .boxGuideOuter.RAL
                ? TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.boxGuideOuter,
                      'WindowColorRal',
                      'name'
                  )
                : TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.boxGuideOuter,
                      'WindowColor',
                      'name'
                  );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.boxInner)
            && details.rollerShutter.colors.boxInner
        ) {
            details.rollerShutter.colors.boxInner.name = details.rollerShutter.colors.boxInner.RAL
                ? TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.boxInner,
                      'WindowColorRal',
                      'name'
                  )
                : TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.boxInner,
                      'WindowColor',
                      'name'
                  );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.endslat)
            && details.rollerShutter.colors.endslat
        ) {
            details.rollerShutter.colors.endslat.name = details.rollerShutter.colors.endslat.RAL
                ? TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.endslat,
                      'WindowColorRal',
                      'name'
                  )
                : TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.endslat,
                      'WindowColor',
                      'name'
                  );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.guideRail)
            && details.rollerShutter.colors.guideRail
        ) {
            details.rollerShutter.colors.guideRail.name = details.rollerShutter.colors.guideRail.RAL
                ? TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.guideRail,
                      'WindowColorRal',
                      'name'
                  )
                : TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.guideRail,
                      'WindowColor',
                      'name'
                  );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.profile)
            && details.rollerShutter.colors.profile
        ) {
            details.rollerShutter.colors.profile.name = details.rollerShutter.colors.profile.RAL
                ? TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.profile,
                      'WindowColorRal',
                      'name'
                  )
                : TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.profile,
                      'WindowColor',
                      'name'
                  );
        }
        if (
            Common.isDefined(details.rollerShutter.colors.revision)
            && details.rollerShutter.colors.revision
        ) {
            details.rollerShutter.colors.revision.name = details.rollerShutter.colors.revision.RAL
                ? TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.revision,
                      'WindowColorRal',
                      'name'
                  )
                : TranslateService.translate(
                      translatedData,
                      details.rollerShutter.colors.revision,
                      'WindowColor',
                      'name'
                  );
        }
        if (Common.isDefined(details.rollerShutter.drive) && details.rollerShutter.drive) {
            details.rollerShutter.drive.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.drive,
                'RollerShutterDrive',
                'name'
            );
        }
        if (Common.isDefined(details.rollerShutter.hanger) && details.rollerShutter.hanger) {
            details.rollerShutter.hanger.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.hanger,
                'RollerShutterHanger',
                'name'
            );
        }
        if (Common.isDefined(details.rollerShutter.profile) && details.rollerShutter.profile) {
            details.rollerShutter.profile.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.profile,
                'Profile',
                'name'
            );
        }
        if (Common.isDefined(details.rollerShutter.roundReel) && details.rollerShutter.roundReel) {
            details.rollerShutter.roundReel.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.roundReel,
                'RoundReel',
                'name'
            );
        }
        if (Common.isDefined(details.rollerShutter.system) && details.rollerShutter.system) {
            details.rollerShutter.system.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.system,
                'RollerShutterSystem',
                'name'
            );
        }
        if (Common.isDefined(details.rollerShutter.type) && details.rollerShutter.type) {
            details.rollerShutter.type.name = TranslateService.translate(
                translatedData,
                details.rollerShutter.type,
                'WindowLine',
                'name'
            );
        }
        if (Common.isArray(details.rollerShutter.driveAccessories)) {
            for (let i = 0; i < details.rollerShutter.driveAccessories.length; i++) {
                details.rollerShutter.driveAccessories[i].name = TranslateService.translate(
                    translatedData,
                    details.rollerShutter.driveAccessories[i],
                    details.rollerShutter.driveAccessories[i].type === 'rollerShutterManual' ? 'RollerShutterDrivesManual' : 'WindowAccessory',
                    'name'
                );
            }
        }

        details.rollerShutter.guideRailsArr = [];
        if (
            Common.isDefined(details.rollerShutter.guideRails)
            && details.rollerShutter.guideRails
            && details.rollerShutter.shutters
        ) {
            details.rollerShutter.shutters.forEach(shutter => {
                const guides = details.rollerShutter.guideRails
                    .filter(rail => rail.leftOf === shutter.id || rail.rightOf === shutter.id)
                    .sort((rail1, rail2) => {
                        if (rail1.side.includes('common') || rail2.side.includes('common')) {
                            return -1;
                        }
                        if (rail1.side > rail2.side) {
                            return 1;
                        } else if (rail1.side === rail2.side) {
                            return 0;
                        }
                        return -1;
                    });

                if (details.rollerShutter.guideRails.length === 2) {
                    guides.forEach(guide => {
                        let sideText = '';
                        if (guide.leftOf !== null) {
                            sideText = this.translateFilter('INTERFACE|Lewa');
                        } else if (guide.rightOf !== null) {
                            sideText = this.translateFilter('INTERFACE|Prawa');
                        }
                        const profile = TranslateService.translate(
                            translatedData,
                            guide,
                            'Profile',
                            'name'
                        );
                        if (
                            !details.rollerShutter.guideRailsArr.find(
                                el => el.originalSide === guide.side
                            )
                        ) {
                            details.rollerShutter.guideRailsArr.push({
                                name: '',
                                side: sideText,
                                profile,
                                shutterNumber: '',
                                originalSide: guide.side,
                            });
                        }
                    });
                } else {
                    guides.forEach(guide => {
                        let sideText = '';
                        let shutterNumber = null;
                        if (guide.leftOf !== null) {
                            sideText = this.translateFilter('INTERFACE|Lewa');
                        } else if (guide.rightOf !== null) {
                            sideText = this.translateFilter('INTERFACE|Prawa');
                        }
                        if (guide.leftOf !== null && guide.rightOf !== null) {
                            shutterNumber = `${guide.rightOf + 1}/${guide.leftOf + 1}`;
                            sideText = this.translateFilter('INTERFACE|Wspólna');
                        }
                        const profile = TranslateService.translate(
                            translatedData,
                            guide,
                            'Profile',
                            'name'
                        );
                        if (
                            !details.rollerShutter.guideRailsArr.find(
                                el => el.originalSide === guide.side
                            )
                        ) {
                            details.rollerShutter.guideRailsArr.push({
                                name: this.translateFilter('WINDOW|Kwatera'),
                                side: sideText,
                                profile,
                                shutterNumber:
                                    shutterNumber !== null ? shutterNumber : shutter.id + 1,
                                originalSide: guide.side,
                            });
                        }
                    });
                }
            });
        }


        details.rollerShutter.slats = [];

        if (Common.isArray(conf.rollerShutter.slats)) {
            details.rollerShutter.slats = conf.rollerShutter.slats.map(slat => ({
                ...slat,
                name: TranslateService.translate(
                    translatedData,
                    slat,
                    'Profile',
                    'name'
                )
            }));
        }

        details.rollerShutter.colors.price =
            this.priceFromSegments(prices, priceParts, { value: { type: 'rollerColor' } })
            + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerColors' } })
            + this.priceFromSegments(prices, priceParts, {
                percent: { type: 'rollerColorsPercent' },
            });
        if (details.rollerShutter.drive) {
            details.rollerShutter.drive.price = this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerDrive' },
            });
        }
        details.rollerShutter.price = {
            boxes:
                this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: null },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'margin' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'market' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'marketMargin' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'rollerMultiplier' },
                }),
            boxSize: this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerBoxSize' },
            }),
            changedSize: this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerChangedSize' },
            }),
            driveManual: this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerDriveManual' },
            }),
            driveElems: this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerDriveElems' },
            }),
            overload: this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerOverload' },
            }),
            drive:
                this.priceFromSegments(prices, priceParts, { value: { type: 'rollerDrive' } })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerDriveManual' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerDriveElems' },
                })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerOverload' } }),
            construction:
                this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: null },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'margin' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'market' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'marketMargin' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerBoxes' },
                    percent: { type: 'rollerMultiplier' },
                })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerBoxSize' } })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerShutterMosquito' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerShutterGuideRail' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerShutterSlat' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerChangedSize' },
                })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerOverload' } })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerConsole' } }),
            all:
                this.priceFromSegments(prices, priceParts, { value: { type: 'rollerColor' } })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerColors' } })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerDrive' } })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerBoxes' } })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerBoxSize' } })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerShutterMosquito' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerShutterGuideRail' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerShutterSlat' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerDriveElems' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerChangedSize' },
                })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerOverload' } })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerConsole' } })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerDriveManual' },
                })
                + this.priceFromSegments(prices, priceParts, {
                    value: { type: 'rollerSlatDivision' },
                })
                + this.priceFromSegments(prices, priceParts, { value: { type: 'rollerAssembly' } }),
            mosquito: this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerShutterMosquito' },
            }),
            rollerSlatDivision: this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerSlatDivision' },
            }),
            guides: this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerShutterGuideRail' },
            }),
            slats: this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerShutterSlat' },
            }),
        };
        if (this.IccConfig.Configurators.roller_shutter.showRollerAssembly) {
            details.rollerShutter.price.assembly = this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerAssembly' },
            });
        } else {
            details.rollerShutter.price.boxes += this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerAssembly' },
            });
            details.rollerShutter.price.construction += this.priceFromSegments(prices, priceParts, {
                value: { type: 'rollerAssembly' },
            });
        }

        if (details.rollerShutter.driveAccessories.length) {
            for (let i = 0; i < details.rollerShutter.driveAccessories.length; i++) {
                details.rollerShutter.driveAccessories[i].price = this.priceFromSegments(
                    prices,
                    priceParts,
                    {
                        value: {
                            type: 'rollerDriveElems',
                            data: { id: details.rollerShutter.driveAccessories[i].id },
                        },
                    }
                );
            }
        }

        return details;
    }

    /**
     * Funkcja zwracająca dane rolet
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsAccessories({ conf, details, prices, priceParts, translatedData }) {
        for (let i = 0; i < conf.accessories.length; i++) {
            const data = {
                id: conf.accessories[i].id,
                amount: conf.accessories[i].amount,
            };
            if (conf.type === 'complementary_goods') {
                data[`good`] = 'accessory';
            }
            if (conf.accessories[i].colorSet) {
                data[`colorField`] = conf.accessories[i].colorSet;
            }
            if (conf.accessories[i].color) {
                if (conf.accessories[i].color.accessory) {
                    data[`colorId`] = conf.accessories[i].color.accessory.id;
                    conf.accessories[i].color.accessory.name = TranslateService.translate(
                        translatedData,
                        conf.accessories[i].color.accessory,
                        'WindowHandlesColor',
                        'name'
                    );
                }
                if (conf.accessories[i].color.profile) {
                    ['core', 'inner', 'outer', 'alushell'].forEach(side => {
                        if (conf.accessories[i].color.profile[side]) {
                            data[`colorSash.frame.${side}.id`] =
                                conf.accessories[i].color.profile[side].id;
                            conf.accessories[i].color.profile[
                                side
                            ].name = TranslateService.translate(
                                translatedData,
                                conf.accessories[i].color.profile[side],
                                'WindowColor',
                                'name'
                            );
                        } else {
                            data[`colorSash.frame.${side}.id`] = null;
                        }
                    });
                }
            }
            details.accessories.construction.push(
                Common.extend(core.copy(conf.accessories[i]), {
                    name: TranslateService.translate(
                        translatedData,
                        conf.accessories[i],
                        'WindowAccessory',
                        'name'
                    ),
                    price: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type:
                                conf.type === 'complementary_goods'
                                    ? 'complementaryGoods'
                                    : 'accessories',
                            data,
                        },
                    }),
                })
            );
        }

        for (const key in conf.sideAccessories) {
            for (let i = 0; i < conf.sideAccessories[key].length; i++) {
                const data = {
                    id: conf.sideAccessories[key][i].id,
                    side: key,
                    amount: conf.sideAccessories[key][i].amount,
                };
                if (conf.sideAccessories[key][i].colorSet) {
                    data[`colorField`] = conf.sideAccessories[key][i].colorSet;
                }
                if (
                    conf.sideAccessories[key][i].color
                ) {
                    if (conf.sideAccessories[key][i].color.accessory) {
                        data[`colorId`] = conf.sideAccessories[key][i].color.accessory.id;
                        conf.sideAccessories[key][i].color.accessory.name = TranslateService.translate(
                            translatedData,
                            conf.sideAccessories[key][i].color.accessory,
                            'WindowHandlesColor',
                            'name'
                        );
                    }

                    if (
                        conf.sideAccessories[key][i].color.profile
                    ) {
                        ['core', 'inner', 'outer', 'alushell'].forEach(side => {
                            if (conf.sideAccessories[key][i].color.profile[side]) {
                                data[`colorSash.frame.${side}.id`] =
                                    conf.sideAccessories[key][i].color.profile[side].id;
                                conf.sideAccessories[key][i].color.profile[
                                    side
                                ].name = TranslateService.translate(
                                    translatedData,
                                    conf.sideAccessories[key][i].color.profile[side],
                                    'WindowColor',
                                    'name'
                                );
                            } else {
                                data[`colorSash.frame.${side}.id`] = null;
                            }
                        });
                    }
                }
                details.accessories.side[key].push(
                    Common.extend(core.copy(conf.sideAccessories[key][i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.sideAccessories[key][i],
                            'WindowAccessory',
                            'name'
                        ),
                        price: this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'sideAccessories',
                                data,
                            },
                        }),
                    })
                );
            }
        }

        return details;
    }

    /**
     * Funkcja zwracająca dane bram garażowych
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsGarage({ conf, details, prices, translatedData }) {
        details.system = conf.system;
        details.system.name = TranslateService.translate(
            translatedData,
            details.system,
            'WindowLine',
            'name'
        );
        details.outerColor = conf.outerColor;
        if (conf.outerColor) {
            details.outerColor.name = TranslateService.translate(
                translatedData,
                conf.outerColor,
                'GaragePanelStructureColor',
                'name'
            );
        }
        details.panel = conf.panel;
        details.panel.name = TranslateService.translate(
            translatedData,
            details.panel,
            'GaragePanel',
            'name'
        );
        details.structure = conf.structure;
        details.structure.name = TranslateService.translate(
            translatedData,
            details.structure,
            'GaragePanelStructure',
            'name'
        );
        details.guide = conf.guide;
        details.guide.name = TranslateService.translate(
            translatedData,
            details.guide,
            'GaragePanelGuide',
            'name'
        );
        details.insulation = conf.insulation;
        details.control = conf.control;
        details.dimensions = conf.dimensions;
        if (details.control) {
            details.control.name = TranslateService.translate(
                translatedData,
                details.control,
                'GarageControl',
                'name'
            );
        }
        details.mountingLocation = conf.mountingLocation;
        details.dimensionsType = conf.dimensionsType;

        return details;
    }

    /**
     * Funkcja zwracająca dane siatek
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsMosquito({ conf, details, prices, priceParts, translatedData }) {
        details.width = conf.width;
        details.height = conf.height;
        details.system = conf.system;
        details.system.name = TranslateService.translate(
            translatedData,
            details.system,
            'WindowLine',
            'name'
        );
        details.variant = conf.variant;
        if (details.variant) {
            details.variant.name = TranslateService.translate(
                translatedData,
                details.variant,
                'MosquitoType',
                'name'
            );
        }
        details.colors = {};
        const mosquitoColors = {
            gray: this.translateFilter('COLOR|Szary'),
            black: this.translateFilter('COLOR|Szary'),
            white: this.translateFilter('COLOR|Biały'),
            brown: this.translateFilter('COLOR|Brązowy')
        };
        ['frame', 'net', 'panel'].forEach(side => {
            details.colors[side] = null;
            if (conf.colors[side] && conf.colors[side].name) {
                details.colors[side] = conf.colors[side];
                if (side === 'frame') {
                    details.colors[side].name = TranslateService.translate(
                        translatedData,
                        details.colors[side],
                        'WindowColor',
                        'name'
                    );
                } else {
                    details.colors[side].name = mosquitoColors[details.colors[side].code];
                }
            }
        });
        if (details.variant && details.variant.hasDirection) {
            details.direction = conf.direction === 'L' ? 'L' : 'R';
        }
        if (conf.montage && conf.montage.id) {
            details.montage = conf.montage;
            details.montage.name = TranslateService.translate(
                translatedData,
                details.montage,
                'MosquitoMontage',
                'name'
            );
        }
        if (conf.windowLine && conf.windowLine.id) {
            details.windowLine = conf.windowLine;
            details.windowLine.name = TranslateService.translate(
                translatedData,
                details.windowLine,
                'WindowLine',
                'name',
                'windowLineId'
            );
        }
        details.profilePositions = conf.profilePositions;
        details.holdersWidth = conf.holdersWidth;
        details.muntinPosition = conf.muntinPosition;
        details.price =
            this.priceFromSegments(prices, priceParts, {
                value: { type: 'mosquitoSize', data: { sashId: conf.sashId } },
            })
            + this.priceFromSegments(prices, priceParts, {
                value: { type: 'mosquitoColor', data: { sashId: conf.sashId } },
            })
            + this.priceFromSegments(prices, priceParts, {
                value: { type: 'mosquitoMontage', data: { sashId: conf.sashId } },
            })
            + this.priceFromSegments(prices, priceParts, {
                value: { type: 'mosquitoProfile', data: { sashId: conf.sashId } },
            });

        return details;
    }

    /**
     * Funkcja zwracająca dane siatek
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsWinterGarden({ conf, details, prices, translatedData }) {
        details.garden = conf.garden;
        details.garden.name = TranslateService.translate(
            translatedData,
            details.garden,
            'WinterGarden',
            'name'
        );

        details.dimensions = conf.dimensions;
        details.handleFacet = conf.handleFacet;
        details.description = conf.description;

        return details;
    }

    /**
     * Funkcja zwracająca dane markiz
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsAwning({ conf, details, prices, translatedData }) {
        details.system = conf.system;
        details.system.name = TranslateService.translate(
            translatedData,
            details.system,
            'AwningLine',
            'name'
        );

        details.outreach = conf.outreach;

        details.color = conf.color;
        details.color.name = TranslateService.translate(
            translatedData,
            details.color,
            'AwningConstructionColour',
            'name'
        );
        details.fabric = conf.fabric;
        details.fabric.name = TranslateService.translate(
            translatedData,
            details.fabric,
            'AwningFabric',
            'name'
        );
        details.valance = conf.valance;
        details.valance.name = TranslateService.translate(
            translatedData,
            details.valance,
            'AwningValance',
            'name'
        );
        details.drive = conf.drive;
        details.drive.name =
            conf.drive.type === 'motor'
                ? TranslateService.translate(translatedData, details.drive, 'AwningMotor', 'name')
                : TranslateService.translate(translatedData, details.drive, 'AwningCrank', 'name');

        details.mounting = conf.mounting;
        details.mounting.name = TranslateService.translate(
            translatedData,
            details.mounting,
            'AwningMounting',
            'name'
        );

        return details;
    }
    /**
     * Funkcja zwracająca dane montaży
     * @param  {object} pos     Pozycja
     * @param  {object} details Dane dla widoku
     * @return {object}         Dane dla widoku
     */
    positionMontages({ pos, details }) {
        const montages = core.parseJson(this.offer.montages);
        details.montages = {
            own: [],
            producer: [],
            own_cost: 0,
            producer_cost: 0
        }
        if (montages.montage) {
            for (const group in montages.groups) {
                if (montages.groups[group].positions.find(el => el.id === pos.tmp_id)) {
                    if (montages.groups[group].own_montages.some(el => el.selected)) {
                        details.montages.own_cost = montages.groups[group].own_montage_cost;
                        montages.groups[group].own_montages.forEach(montage => {
                            if (montage.selected) {
                                details.montages.own.push({
                                    id: montage.Montage.id,
                                    name: montage.Montage.name
                                });
                            }
                        });
                    }
                    if (montages.groups[group].producer_montages.some(el => el.selected)) {
                        details.montages.producer_cost = montages.groups[group].producer_montage_cost;
                        montages.groups[group].producer_montages.forEach(montage => {
                            if (montage.selected) {
                                details.montages.producer.push({
                                    id: montage.Montage.id,
                                    name: montage.Montage.name
                                });
                            }
                        });
                    }
                }
            }
        }

        return details;
    }

    /**
     * Funkcja zwracająca dane kasonetek
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsComplementaryGoods({ conf, details, prices, priceParts, translatedData }) {
        details.cassonettos = null;
        details.fillings = null;
        details.windowSills = null;
        details.profiles = null;

        if (conf.cassonettos && conf.cassonettos.length) {
            details.cassonettos = {
                price: this.priceFromSegments(prices, priceParts, {
                    value: { type: 'complementaryGoods', data: { good: 'cassonetto' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.cassonettos.length; i++) {
                details.cassonettos.elems.push(
                    Common.extend(core.copy(conf.cassonettos[i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.cassonettos[i],
                            'Cassonetto',
                            'name'
                        ),
                        price: this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'complementaryGoods',
                                data: {
                                    id: Number(conf.cassonettos[i].id),
                                    good: 'cassonetto',
                                    currentHeight: conf.cassonettos[i].height,
                                    currentWidth: conf.cassonettos[i].width,
                                    color: conf.cassonettos[i].color.id,
                                    p1: conf.cassonettos[i].p1,
                                    p2: conf.cassonettos[i].p2,
                                    l1: conf.cassonettos[i].l1,
                                },
                            },
                        }),
                    })
                );
            }
        }

        if (conf.fillings && conf.fillings.length) {
            details.fillings = {
                price: this.priceFromSegments(prices, priceParts, {
                    value: { type: 'complementaryGoods', data: { good: 'glass' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.fillings.length; i++) {
                details.fillings.elems.push({
                    count: conf.fillings[i].count,
                    height: conf.fillings[i].height,
                    width: conf.fillings[i].width,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'complementaryGoods',
                            data: {
                                id: Number(conf.fillings[i].filling.id),
                                good: 'glass',
                                warmEdge: conf.fillings[i].glazingSpacer.id,
                                currentWidth: conf.fillings[i].width,
                                currentHeight: conf.fillings[i].height,
                            },
                        },
                    }),
                    filling: Common.extend(core.copy(conf.fillings[i].filling), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.fillings[i].filling,
                            'Filling',
                            'name'
                        ),
                    }),
                    glazingSpacer: Common.extend(core.copy(conf.fillings[i].glazingSpacer), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.fillings[i].glazingSpacer,
                            'WindowWarmEdge',
                            'name'
                        ),
                    }),
                });
            }
        }

        if (conf.windowSills && conf.windowSills.length) {
            details.windowSills = {
                price: this.priceFromSegments(prices, priceParts, {
                    value: { type: 'complementaryGoods', data: { good: 'windowsill' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.windowSills.length; i++) {
                details.windowSills.elems.push(
                    Common.extend(core.copy(conf.windowSills[i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.windowSills[i],
                            'WindowSill',
                            'name'
                        ),
                        price: this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'complementaryGoods',
                                data: {
                                    id: Number(conf.windowSills[i].id),
                                    good: 'windowsill',
                                    currentLength: conf.windowSills[i].length,
                                    currentWidth: conf.windowSills[i].width,
                                    plugs: conf.windowSills[i].plugs || false,
                                },
                            },
                        }),
                    })
                );
            }
        }

        if (conf.profiles && conf.profiles.length) {
            details.profiles = {
                price: this.priceFromSegments(prices, priceParts, {
                    value: { type: 'complementaryGoods', data: { good: 'profile' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.profiles.length; i++) {
                const data = {
                    id: conf.profiles[i].profile.id,
                    good: 'profile',
                    amount: conf.profiles[i].length,
                    count: conf.profiles[i].count,
                };
                if (conf.profiles[i].color) {
                    if (conf.profiles[i].color.accessory) {
                        data[`colorId`] = conf.profiles[i].color.accessory.id;
                    }
                    if (conf.profiles[i].color.profile) {
                        ['core', 'inner', 'outer', 'alushell'].forEach(side => {
                            if (conf.profiles[i].color.profile[side]) {
                                data[`colorSash.frame.${side}.id`] =
                                    String(conf.profiles[i].color.profile[side].id);
                                conf.profiles[i].color.profile[
                                    side
                                ].name = TranslateService.translate(
                                    translatedData,
                                    conf.profiles[i].color.profile[side],
                                    'WindowColor',
                                    'name'
                                );
                            } else {
                                data[`colorSash.frame.${side}.id`] = undefined;
                            }
                        });
                    }
                }
                details.profiles.elems.push(
                    Common.extend(core.copy(conf.profiles[i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.profiles[i].profile,
                            'Profile',
                            'name'
                        ),
                        price: this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'complementaryGoods',
                                data,
                            },
                        }),
                    })
                );
            }
        }

        details.img = this.getComplementaryGoodsImg(conf);

        return details;
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} details        Szczegóły pozycji
     * @param  {object} pos            Pozycja
     * @param  {object} conf           Konfiguracja
     * @param  {array}  prices         Tablica segmentów cen
     * @return {object}                Dane pozycji dla widoku
     */
    positionDetailsColorsCost({ conf, details, translatedData, discounts }) {
        details.colors = [];
        conf.colors.forEach(group => {
            const posGroup = {
                system: null,
                wood: null,
                alushell: null,
                colors: null,
                prices: {
                    client: {
                        price: group.price * (1 - discounts.client / 100),
                        priceBeforeDiscount: group.price,
                        discount: discounts.client
                    },
                    dealer: {
                        price: group.dealerPrice * (1 - discounts.dealer / 100),
                        priceBeforeDiscount: group.dealerPrice,
                        discount: discounts.dealer
                    }
                }
            };
            if (Common.isObject(group.system)) {
                posGroup.system = group.system;
                posGroup.system.name = TranslateService.translate(
                    translatedData,
                    posGroup.system,
                    'WindowLine',
                    'name'
                );
            }
            posGroup.wood = !group.wood
                ? null
                : {
                      id: group.wood.id,
                      name: TranslateService.translate(
                          translatedData,
                          group.wood,
                          'WoodType',
                          'name'
                      ),
                  };
            posGroup.alushell = {
                has: group.hasAlushell,
                type: group.alushellType,
            };
            let colorHeaderType = '';
            let colorHeaderName = '';
            if (!group.colorsSashExt) {
                if (group.colorType === 'White') {
                    colorHeaderType = this.translateFilter('COLOR|Kolor');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.core,
                        'WindowColor',
                        'name'
                    );
                } else if (group.colorType === 'Cream') {
                    colorHeaderType = this.translateFilter('COLOR|Kolor');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.core,
                        'WindowColor',
                        'name'
                    );
                } else if (group.colorType === 'Outer') {
                    colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny zewnętrzny');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.outer,
                        'WindowColor',
                        'name'
                    );
                } else if (group.colorType === 'Inner') {
                    colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny wewnętrzny');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.inner,
                        'WindowColor',
                        'name'
                    );
                } else if (group.colorType === 'Bilateral') {
                    colorHeaderType = this.translateFilter('COLOR|Kolor obustronny');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.outer,
                        'WindowColor',
                        'name'
                    );
                } else if (group.colorType === 'Transparent') {
                    colorHeaderType = this.translateFilter('COLOR|Lakierowanie transparentne');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.core,
                        'WindowColor',
                        'name'
                    );
                } else if (group.colorType === 'Opaque') {
                    colorHeaderType = this.translateFilter('COLOR|Lakierowanie kryjące');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.core,
                        'WindowColor',
                        'name'
                    );
                } else if (group.colorType === '3D') {
                    colorHeaderType = this.translateFilter('COLOR|Kolor 3D');
                    colorHeaderName = TranslateService.translate(
                        translatedData,
                        group.colors.frame.core,
                        'WindowColor',
                        'name'
                    );
                }
            }
            if (group.colorsSashExt || group.colorType === 'Bicolor') {
                colorHeaderType = this.translateFilter('COLOR|Kolor');
                colorHeaderName = this.translateFilter('COLOR|różne');
            }
            const colorHeader = `${colorHeaderType}: ${colorHeaderName}`;
            posGroup.colors = {
                header: colorHeader,
                type: group.colorType,
                sashExt: group.colorsSashExt,
                frame: !group.colors.frame
                    ? null
                    : {
                          alushell: !group.colors.frame.alushell
                              ? null
                              : {
                                    Ral: group.colors.frame.alushell.Ral,
                                    code: group.colors.frame.alushell.code,
                                    isDefault: group.colors.frame.alushell.isDefault,
                                    id: group.colors.frame.alushell.id,
                                    name: group.colors.frame.alushell.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.frame.alushell,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.frame.alushell,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          core: !group.colors.frame.core
                              ? null
                              : {
                                    Ral: group.colors.frame.core.Ral,
                                    code: group.colors.frame.core.code,
                                    isDefault: group.colors.frame.core.isDefault,
                                    id: group.colors.frame.core.id,
                                    name: group.colors.frame.core.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.frame.core,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.frame.core,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          inner: !group.colors.frame.inner
                              ? null
                              : {
                                    Ral: group.colors.frame.inner.Ral,
                                    code: group.colors.frame.inner.code,
                                    isDefault: group.colors.frame.inner.isDefault,
                                    id: group.colors.frame.inner.id,
                                    name: group.colors.frame.inner.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.frame.inner,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.frame.inner,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          outer: !group.colors.frame.outer
                              ? null
                              : {
                                    Ral: group.colors.frame.outer.Ral,
                                    code: group.colors.frame.outer.code,
                                    isDefault: group.colors.frame.outer.isDefault,
                                    id: group.colors.frame.outer.id,
                                    name: group.colors.frame.outer.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.frame.outer,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.frame.outer,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                      },
                sash: !group.colors.sash
                    ? null
                    : {
                          alushell: !group.colors.sash.alushell
                              ? null
                              : {
                                    Ral: group.colors.sash.alushell.Ral,
                                    code: group.colors.sash.alushell.code,
                                    isDefault: group.colors.sash.alushell.isDefault,
                                    id: group.colors.sash.alushell.id,
                                    name: group.colors.sash.alushell.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.sash.alushell,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.sash.alushell,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          core: !group.colors.sash.core
                              ? null
                              : {
                                    Ral: group.colors.sash.core.Ral,
                                    code: group.colors.sash.core.code,
                                    isDefault: group.colors.sash.core.isDefault,
                                    id: group.colors.sash.core.id,
                                    name: group.colors.sash.core.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.sash.core,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.sash.core,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          inner: !group.colors.sash.inner
                              ? null
                              : {
                                    Ral: group.colors.sash.inner.Ral,
                                    code: group.colors.sash.inner.code,
                                    isDefault: group.colors.sash.inner.isDefault,
                                    id: group.colors.sash.inner.id,
                                    name: group.colors.sash.inner.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.sash.inner,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.sash.inner,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          outer: !group.colors.sash.outer
                              ? null
                              : {
                                    Ral: group.colors.sash.outer.Ral,
                                    code: group.colors.sash.outer.code,
                                    isDefault: group.colors.sash.outer.isDefault,
                                    id: group.colors.sash.outer.id,
                                    name: group.colors.sash.outer.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.sash.outer,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.sash.outer,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                      },
            };
            details.colors.push(posGroup);
        });

        return details;
    }

    /**
     * Konstruktor danych pozycji dla widoku
     * @param  {object} details        Szczegóły pozycji
     * @param  {object} pos            Pozycja
     * @param  {object} conf           Konfiguracja
     * @param  {array}  prices         Tablica segmentów cen
     * @return {object}                Dane pozycji dla widoku
     */
    positionDetailsColorsWasteCost({ conf, details, translatedData }) {
        details.colors = [];
        conf.colors.forEach(group => {
            const posGroup = {
                profile: null,
                wood: null,
                alushell: null,
                colors: null,
                price: group.price,
            };
            if (Common.isObject(group.profile)) {
                posGroup.profile = group.profile;
                posGroup.profile.name = TranslateService.translate(
                    translatedData,
                    posGroup.profile,
                    'Profile',
                    'name'
                );
            }
            posGroup.wood = !group.wood
                ? null
                : {
                      id: group.wood.id,
                      name: TranslateService.translate(
                          translatedData,
                          group.wood,
                          'WoodType',
                          'name'
                      ),
                  };
            posGroup.alushell = {
                has: group.hasAlushell,
                type: group.alushellType,
            };
            let colorHeaderType = '';
            let colorHeaderName = '';
            let colorHeaderCoreName = '';
            if (group.colorType === 'White') {
                colorHeaderType = this.translateFilter('COLOR|Kolor');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name'
                );
            } else if (group.colorType === 'Cream') {
                colorHeaderType = this.translateFilter('COLOR|Kolor');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name'
                );
            } else if (group.colorType === 'Outer') {
                colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny zewnętrzny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.outer,
                    'WindowColor',
                    'name'
                );
                colorHeaderCoreName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name'
                );
            } else if (group.colorType === 'Inner') {
                colorHeaderType = this.translateFilter('COLOR|Kolor jednostronny wewnętrzny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.inner,
                    'WindowColor',
                    'name'
                );
                colorHeaderCoreName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name'
                );
            } else if (group.colorType === 'Bilateral') {
                colorHeaderType = this.translateFilter('COLOR|Kolor obustronny');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.outer,
                    'WindowColor',
                    'name'
                );
                colorHeaderCoreName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name'
                );
            } else if (group.colorType === 'Transparent') {
                colorHeaderType = this.translateFilter('COLOR|Lakierowanie transparentne');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.outer,
                    'WindowColor',
                    'name'
                );
            } else if (group.colorType === 'Opaque') {
                colorHeaderType = this.translateFilter('COLOR|Lakierowanie kryjące');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.outer,
                    'WindowColor',
                    'name'
                );
            } else if (group.colorType === '3D') {
                colorHeaderType = this.translateFilter('COLOR|Kolor 3D');
                colorHeaderName = TranslateService.translate(
                    translatedData,
                    group.colors.outer,
                    'WindowColor',
                    'name'
                );
            } else if (group.colorType === 'Bicolor') {
                colorHeaderType = this.translateFilter('COLOR|Bikolor');
                colorHeaderName =
                    TranslateService.translate(
                        translatedData,
                        group.colors.outer,
                        'WindowColor',
                        'name'
                    )
                    + '/'
                    + TranslateService.translate(
                        translatedData,
                        group.colors.inner,
                        'WindowColor',
                        'name'
                    );
                colorHeaderCoreName = TranslateService.translate(
                    translatedData,
                    group.colors.core,
                    'WindowColor',
                    'name'
                );
            }
            const colorHeaderCore = this.translateFilter('COLOR|Rdzeń');
            const colorHeader =
                `${colorHeaderType}: ${colorHeaderName}`
                + (colorHeaderCoreName ? ` ${colorHeaderCore}: ${colorHeaderCoreName}` : '');
            posGroup.colors = {
                header: colorHeader,
                type: group.colorType,
                sashExt: group.colorsSashExt,
                frame: !group.colors
                    ? null
                    : {
                          alushell: !group.colors.alushell
                              ? null
                              : {
                                    Ral: group.colors.alushell.Ral,
                                    code: group.colors.alushell.code,
                                    isDefault: group.colors.alushell.isDefault,
                                    id: group.colors.alushell.id,
                                    name: group.colors.alushell.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.alushell,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.alushell,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          core: !group.colors.core
                              ? null
                              : {
                                    Ral: group.colors.core.Ral,
                                    code: group.colors.core.code,
                                    isDefault: group.colors.core.isDefault,
                                    id: group.colors.core.id,
                                    name: group.colors.core.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.core,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.core,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          inner: !group.colors.inner
                              ? null
                              : {
                                    Ral: group.colors.inner.Ral,
                                    code: group.colors.inner.code,
                                    isDefault: group.colors.inner.isDefault,
                                    id: group.colors.inner.id,
                                    name: group.colors.inner.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.inner,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.inner,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          outer: !group.colors.outer
                              ? null
                              : {
                                    Ral: group.colors.outer.Ral,
                                    code: group.colors.outer.code,
                                    isDefault: group.colors.outer.isDefault,
                                    id: group.colors.outer.id,
                                    name: group.colors.outer.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.outer,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.outer,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                      },
                sash: !group.colors
                    ? null
                    : {
                          alushell: !group.colors.alushell
                              ? null
                              : {
                                    Ral: group.colors.alushell.Ral,
                                    code: group.colors.alushell.code,
                                    isDefault: group.colors.alushell.isDefault,
                                    id: group.colors.alushell.id,
                                    name: group.colors.alushell.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.alushell,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.alushell,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          core: !group.colors.core
                              ? null
                              : {
                                    Ral: group.colors.core.Ral,
                                    code: group.colors.core.code,
                                    isDefault: group.colors.core.isDefault,
                                    id: group.colors.core.id,
                                    name: group.colors.core.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.core,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.core,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          inner: !group.colors.inner
                              ? null
                              : {
                                    Ral: group.colors.inner.Ral,
                                    code: group.colors.inner.code,
                                    isDefault: group.colors.inner.isDefault,
                                    id: group.colors.inner.id,
                                    name: group.colors.inner.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.inner,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.inner,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                          outer: !group.colors.outer
                              ? null
                              : {
                                    Ral: group.colors.outer.Ral,
                                    code: group.colors.outer.code,
                                    isDefault: group.colors.outer.isDefault,
                                    id: group.colors.outer.id,
                                    name: group.colors.outer.Ral
                                        ? TranslateService.translate(
                                              translatedData,
                                              group.colors.outer,
                                              'WindowColorRal',
                                              'name'
                                          )
                                        : TranslateService.translate(
                                              translatedData,
                                              group.colors.outer,
                                              'WindowColor',
                                              'name'
                                          ),
                                },
                      },
            };
            details.colors.push(posGroup);
        });

        return details;
    }

    getPositionName(pos, translatedData) {
        let name = '';
        if (pos.details.type === 'roller_shutter') {
            name =
                (pos.details.rollerShutter.type
                    ? TranslateService.translate(
                          translatedData,
                          pos.details.rollerShutter.type,
                          'WindowLine',
                          'name'
                      ) + ' > '
                    : '')
                + TranslateService.translate(
                    translatedData,
                    pos.details.rollerShutter.system,
                    'RollerShutterSystem',
                    'name'
                );
        } else {
            name =
                (pos.configuration.SystemType
                    ? TranslateService.translate(
                          translatedData,
                          pos.configuration.SystemType,
                          'SystemType',
                          'name'
                      ) + ' > '
                    : '')
                + TranslateService.translate(
                    translatedData,
                    pos.details.system,
                    'WindowLine',
                    'name'
                );
        }

        if (name === '') {
            switch (pos.details.type) {
                case 'accessory':
                    name = this.translateFilter('ACCESSORY|Akcesoria');
                    break;
                case 'complementary_goods':
                    name = this.IccConfig.Configurators.complementary_goods
                        .namedAccessory
                        ? this.translateFilter('ACCESSORY|Akcesoria')
                        : this.translateFilter('GOODS|Dobra komplementarne');
                    break;
                case 'winter_garden':
                    name = this.translateFilter('WINTERGARDEN|Ogrody zimowe');
                    break;
                case 'coupled_window':
                    name = this.translateFilter('WINDOW|Konstrukcje złożone');
                    break;
                default:
                    name = pos.details.name;
            }
        }

        return name;
    }

    /**
     * Funkcja zwracająca dane markiz
     * @param  {object} conf    Konfiguracja
     * @param  {object} details Dane dla widoku
     * @param  {array}  prices  Tablica segmentów cen
     * @return {object}         Dane dla widoku
     */
    positionDetailsCoupledWindow({
        conf,
        details,
        prices,
        translatedData,
        allPositions,
        priceParts,
        drawData,
    }: {
        conf: CoupledWindowConfiguration;
        details;
        prices;
        translatedData;
        allPositions;
        priceParts;
        drawData;
    }) {
        details.subPositions = [];
        let manySystemAndColors = false;

        const firstGroupCode = conf.windows.length > 0 && conf.windows[0].groupCode;

        conf.windows.forEach(window => {
            const position = core.copy(allPositions.find(p => p.id === window.positionId));
            if (position) {
                new PositionsDetailsService(
                    this.IccConfig,
                    position.doc,
                    this.offer,
                    this.access,
                    this.showPrices,
                    this.priceType,
                    this.translateFilter,
                    this.pdf,
                    this.translatedData,
                    this.showInfo,
                    this.allPositions
                );
                position.doc.detailsForView.partOfcoupledWindow = true;
                position.doc.detailsForView.index =
                    details.index + '.' + core.indexToLetter(window.index);
                details.subPositions.push(position);
            } else if(this.showInfo) {
                this.showInfo(this.translateFilter('OFFER|Błędna konstrukcja złożona. Usuń ją i dodaj ponownie.'));
            }

            manySystemAndColors = manySystemAndColors || window.groupCode !== firstGroupCode;
        });

        conf.rollerShutters.forEach(rollerShutter => {
            const position = core.copy(allPositions.find(p => p.id === rollerShutter.positionId));
            new PositionsDetailsService(
                this.IccConfig,
                position.doc,
                this.offer,
                this.access,
                this.showPrices,
                this.priceType,
                this.translateFilter,
                this.pdf,
                this.translatedData,
                this.showInfo,
                this.allPositions
            );
            position.doc.detailsForView.partOfcoupledWindow = true;
            position.doc.detailsForView.index =
                details.index + '.' + core.indexToLetter(rollerShutter.index);
            details.subPositions.push(position);

            manySystemAndColors = manySystemAndColors || rollerShutter.groupCode !== firstGroupCode;
        });

        details.hasWarranty = true;
        details.hasGlazingWarranty = true;

        details.subPositions.forEach(position => {
            position.doc.detailsForView.manySystemAndColors = manySystemAndColors;
            details.prices.client.base += position.doc.detailsForView.prices.client.base;
            details.prices.client.surcharges +=
                position.doc.detailsForView.prices.client.surcharges;
            details.prices.dealer.base += position.doc.detailsForView.prices.dealer.base;
            details.prices.dealer.surcharges +=
                position.doc.detailsForView.prices.dealer.surcharges;
            details.hasWarranty = details.hasWarranty && position.doc.detailsForView.hasWarranty;
            details.hasGlazingWarranty =
                details.hasGlazingWarranty && position.doc.detailsForView.hasGlazingWarranty;
        });

        details.couplings = {
            isDefault: true,
            couplings: [],
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'couplings' } }),
        };

        if (conf.couplings.length) {
            let topLeft;
            let topLeftExtension;
            let bottomRight;
            let bottomRightExtension;
            let reinforcement;

            for (const coupling of conf.couplings) {
                topLeftExtension = coupling.adjacentSideProfileId.length
                    ? this.getFirstSideProfileById(coupling.adjacentSideProfileId[0], conf)
                    : null;
                topLeft = (topLeftExtension || coupling).framesId.map(f => [f.id, f.positionId])[0];
                bottomRightExtension = coupling.adjacentOtherSideProfileId.length
                    ? this.getFirstSideProfileById(coupling.adjacentOtherSideProfileId[0], conf)
                    : null;
                bottomRight = (bottomRightExtension ? bottomRightExtension.framesId : coupling.otherFramesId).map(f => [
                    f.id,
                    f.positionId,
                ])[0];
                const topLeftWindow =
                    topLeft && details.subPositions.find(w => w.id === topLeft[1]);
                topLeft = topLeftWindow
                    ? topLeftWindow.doc.detailsForView.index
                      + (topLeftWindow.doc.details.frames.length > 1
                          ? '.'
                            + core.indexToLetter(
                                topLeftWindow.doc.details.frames[
                                    core.fId(topLeftWindow.doc.details.frames, topLeft[0], 'id')
                                ].index
                            )
                          : '')
                    : '';
                const bottomRightWindow =
                    bottomRight && details.subPositions.find(w => w.id === bottomRight[1]);
                bottomRight = bottomRightWindow
                    ? bottomRightWindow.doc.detailsForView.index
                      + (bottomRightWindow.doc.details.frames.length > 1
                          ? '.'
                            + core.indexToLetter(
                                bottomRightWindow.doc.details.frames[
                                    core.fId(
                                        bottomRightWindow.doc.details.frames,
                                        bottomRight[0],
                                        'id'
                                    )
                                ].index
                            )
                          : '')
                    : '';

                const profile = core.fIdO(conf.usedProfiles, coupling.profileId);

                reinforcement = coupling.reinforcement;
                if (reinforcement && reinforcement.id !== null) {
                    reinforcement.name = TranslateService.translate(
                        translatedData,
                        reinforcement,
                        'Reinforcement',
                        'name'
                    );
                }

                const couplingDetails = {
                    id: coupling.id,
                    profileId: coupling.profileId,
                    name: TranslateService.translate(translatedData, profile, 'Profile', 'name'),
                    outerWidth: coupling.widthOut,
                    innerWidth: coupling.width,
                    length: coupling.length,
                    direction: coupling.direction,
                    type: profile ? profile.type : null,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: { type: 'couplings', data: { couplingId: coupling.id } },
                    }),
                    topLeft,
                    bottomRight,
                    reinforcement,
                    color: {
                        core:
                            coupling.color && coupling.color.core && coupling.color.core.id
                                ? {
                                      id: coupling.color.core.id,
                                      RAL: coupling.color.core.RAL,
                                      code: coupling.color.core.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          coupling.color.core,
                                          coupling.color.core.RAL ? 'WindowColorRal' : 'WindowColor',
                                          'name'
                                      ),
                                  }
                                : null,
                        inner:
                            coupling.color && coupling.color.inner && coupling.color.inner.id
                                ? {
                                      id: coupling.color.inner.id,
                                      RAL: coupling.color.inner.RAL,
                                      code: coupling.color.inner.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          coupling.color.inner,
                                          coupling.color.inner.RAL ? 'WindowColorRal' : 'WindowColor',
                                          'name'
                                      ),
                                  }
                                : null,
                        outer:
                            coupling.color && coupling.color.outer && coupling.color.outer.id
                                ? {
                                      id: coupling.color.outer.id,
                                      RAL: coupling.color.outer.RAL,
                                      code: coupling.color.outer.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          coupling.color.outer,
                                          coupling.color.outer.RAL ? 'WindowColorRal' : 'WindowColor',
                                          'name'
                                      ),
                                  }
                                : null,
                    },
                };
                details.couplings.couplings.push(couplingDetails);
            }
        }

        details.extensions = {
            extensions: [],
            price: this.priceFromSegments(prices, priceParts, { value: { type: 'sideProfile' } }),
            show: false,
        };

        if (drawData && drawData.extension && conf.sideProfiles && conf.sideProfiles.length) {
            details.extensions.show = true;

            let adjacentFrame;
            let adjacentExtension;

            conf.sideProfiles.map(extension => {
                adjacentExtension = this.getFirstSideProfileById(extension.id, conf);
                adjacentFrame = (adjacentExtension || extension).framesId.map(f => [
                    f.id,
                    f.positionId,
                    f.edges[0],
                ])[0];
                const adjacentWindow =
                    adjacentFrame && details.subPositions.find(w => w.id === adjacentFrame[1]);
                const adjacentFrameIndex = adjacentWindow
                    ? adjacentWindow.doc.detailsForView.index
                      + (adjacentWindow.doc.details.frames.length > 1
                          ? '.'
                            + core.indexToLetter(
                                adjacentWindow.doc.details.frames[
                                    core.fId(
                                        adjacentWindow.doc.details.frames,
                                        adjacentFrame[0],
                                        'id'
                                    )
                                ].index
                            )
                          : '')
                    : '';
                const extensionProfile = conf.usedProfiles.find(
                    usedProfile => usedProfile.id === extension.profileId
                );
                const extensionDimensions = drawData.extension.find(
                    el => el.extensionId === extension.id
                );

                details.extensions.extensions.push({
                    id: extension.id,
                    code: extensionProfile ? extensionProfile.code : null,
                    count: extension.count || 1,
                    name: extensionProfile
                        ? TranslateService.translate(
                              translatedData,
                              extensionProfile,
                              'Profile',
                              'name'
                          )
                        : '',
                    type: extensionProfile ? extensionProfile.type : null,
                    width: extensionDimensions.rect.width,
                    height: extensionDimensions.rect.height,
                    price: this.priceFromSegments(prices, priceParts, {
                        value: {
                            type: 'sideProfile',
                            data: { id: extension.id, profileId: extension.profileId },
                        },
                    }),
                    frameIndex: adjacentFrameIndex,
                    frameEdgeIndex: adjacentFrame && adjacentFrame.length > 2 && adjacentFrame[2],
                    side: extension.side,
                    color: {
                        core:
                            extension.color && extension.color.core && extension.color.core.id
                                ? {
                                      id: extension.color.core.id,
                                      RAL: extension.color.core.RAL,
                                      code: extension.color.core.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          extension.color.core,
                                          'WindowColor',
                                          'name'
                                      ),
                                  }
                                : null,
                        inner:
                            extension.color && extension.color.inner && extension.color.inner.id
                                ? {
                                      id: extension.color.inner.id,
                                      RAL: extension.color.inner.RAL,
                                      code: extension.color.inner.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          extension.color.inner,
                                          'WindowColor',
                                          'name'
                                      ),
                                  }
                                : null,
                        outer:
                            extension.color && extension.color.outer && extension.color.outer.id
                                ? {
                                      id: extension.color.outer.id,
                                      RAL: extension.color.outer.RAL,
                                      code: extension.color.outer.code,
                                      name: TranslateService.translate(
                                          translatedData,
                                          extension.color.outer,
                                          'WindowColor',
                                          'name'
                                      ),
                                  }
                                : null,
                    },
                });
            });
        }

        if (conf.windowSills && conf.windowSills.length) {
            details.windowSills = {
                price: this.priceFromSegments(prices, priceParts, {
                    value: { type: 'complementaryGoods', data: { good: 'windowsill' } },
                }),
                elems: [],
            };

            for (let i = 0; i < conf.windowSills.length; i++) {
                details.windowSills.elems.push(
                    Common.extend(core.copy(conf.windowSills[i]), {
                        name: TranslateService.translate(
                            translatedData,
                            conf.windowSills[i],
                            'WindowSill',
                            'name'
                        ),
                        price: this.priceFromSegments(prices, priceParts, {
                            value: {
                                type: 'complementaryGoods',
                                data: {
                                    id: Number(conf.windowSills[i].id),
                                    good: 'windowsill',
                                    currentLength: conf.windowSills[i].length,
                                    currentWidth: conf.windowSills[i].width,
                                    plugs: conf.windowSills[i].plugs || false,
                                },
                            },
                        }),
                    })
                );
            }
        }

        return details;
    }

    getComplementaryGoodsImg(conf) {
        let image = null;
        if (
            this.complementaryGoods.reduce(
                (prev, complementaryGood) => prev + conf[complementaryGood].length,
                0
            ) === 1
        ) {
            const complementaryGood = this.complementaryGoods.find(el => conf[el].length);
            image = conf[complementaryGood][0].image;
        }
        return image;
    }

    getFirstSideProfileById(sideProfileId: number, conf: CoupledWindowConfiguration) {
        const lastProfiles = conf.sideProfiles.filter(s => s.id === sideProfileId);
        const profiles = lastProfiles
            .map(s =>
                s.adjacentSideProfileId.length > 0 ? this.getBackProfiles(s, conf.sideProfiles) : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    getLastSideProfileById(sideProfileId: number, conf: CoupledWindowConfiguration) {
        const lastProfiles = conf.sideProfiles.filter(s => s.id === sideProfileId);
        const profiles = lastProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0
                    ? this.getNextProfiles(s, conf.sideProfiles)
                    : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    private getBackProfiles(baseProfile: SideProfile, sideProfiles: SideProfile[]): SideProfile[] {
        const backProfiles = sideProfiles.filter(s =>
            baseProfile.adjacentSideProfileId.includes(s.id)
        );
        const allBackProfiles = backProfiles
            .map(s =>
                s.adjacentSideProfileId.length > 0 ? this.getBackProfiles(s, sideProfiles) : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return allBackProfiles;
    }

    private getNextProfiles(baseProfile: SideProfile, sideProfiles: SideProfile[]): SideProfile[] {
        const backProfiles = sideProfiles.filter(s =>
            baseProfile.adjacentOtherSideProfileId.includes(s.id)
        );
        const allBackProfiles = backProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0 ? this.getNextProfiles(s, sideProfiles) : s
            )
            .reduce<SideProfile[]>((acc, val) => acc.concat(val), []);
        return allBackProfiles;
    }
}
