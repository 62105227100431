import angular from 'angular';
import $ from 'jquery';
const colorsModalTemplate = require('ngtemplate-loader!../../complementary_goods/colors/modal.html');

/**
 * Fabryka akcesoriów
 * @param {object} $rootScope               Angular root scope
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} Core                     Core
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} accessoriesFilter        Filtr akceroriów
 * @param {object} PriceService             Liczenie cen
 * @param {object} ParametersService        Fabryka parametrów
 * @param {object} PriceAccessoryService    Fabryka podstawowej ceny
 * @param {object} StepFactory              Fabryka kroków
 * @param {object} IccConfig                Konfiguracja ICC
 */
export default function AccessoriesFactory(
    $rootScope,
    $uibModal,
    Core,
    ConfigurationsService,
    CurConfService, // jshint ignore:line
    ConfiguratorsDataService,
    accessoriesFilter,
    PriceService,
    ParametersService,
    $timeout,
    PriceAccessoryService,
    StepFactory,
    IccConfig,
    $filter,
    ColorsFactory,
    IccDrawingMathFactory,
    EventBusService,
    TimeLimitService,
    ProfilesService,
    ScrollbarService
) {
    'ngInject';

    const IccMath = IccDrawingMathFactory;

    var configurators = [
        'window',
        'hs',
        'door',
        'folding_door',
        'accessory',
        'garage',
        'roller_shutter',
    ];
    var allAccessories = [];
    var accessories = [];
    var categories = [];
    var subcategories = [];
    var sashHardwares = [];
    var priceProp = 'price_white';
    var dowelHoleParams = {
        between: 700,
        fromEdge: 200,
    };

    var factory = {
        colorsAll: [],
        loadedData: false,
        findAccessories,
        updateAccessories,
        add,
        remove,
        refreshPriceProp,
        openModalAccessories,
        dowelHoleParams,
        countUp,
        countDown,
        countPrice,
        removeAllFromSide,
        openModalColors,
        getCountHoles,
        placeDowelHole,
        selectedVentilatorType,
        getAccessory,
        setAmountAccessory,
        setDefaultColors,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('refreshPriceProp', refreshPriceProp);

    EventBusService.subscribe(
        [
            'changedSashes',
            'setGlazingInSash',
            'setShape',
            'setExtensionProfile',
            'setFrameProfile',
            'setSashProfile',
            'setMullionProfile',
            'putAlignmentInField',
            'removedAlignmentInField',
            'removedAlignment',
            'setLowThreshold',
            'unsetLowThreshold',
        ],
        data => {
            updateAccessories(data.activeConfiguration);
        }
    );

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (configurators.indexOf(CurConfService.conf) === -1) {
            return;
        }

        // wszystkie akcesorie połączone (sash, sides i conf)
        allAccessories = ConfiguratorsDataService.data.windowAccessories;
        subcategories = ConfiguratorsDataService.data.windowAccessoriesSubCategories || [];
        factory.colorsAll = ConfiguratorsDataService.data.windowHandlesColors;
        dowelHoleParams.between = ConfiguratorsDataService.data.dowelHoleParams
            ? ConfiguratorsDataService.data.dowelHoleParams.between
            : 0;
        dowelHoleParams.fromEdge = ConfiguratorsDataService.data.dowelHoleParams
            ? ConfiguratorsDataService.data.dowelHoleParams.fromEdge
            : 0;
        categories = ConfiguratorsDataService.data.windowAccessoriesCategories;

        findAccessories();
        refreshPriceProp();
        factory.loadedData = true;

        if (CurConfService.conf == 'accessory') {
            ConfigurationsService.conf.Current.Name = $filter('translate')('ACCESSORY|Akcesoria');
        }
    }

    /**
     * Funkcja znajdujaca akcesoria
     */
    function findAccessories(conf = ConfigurationsService.conf.Current, confType = CurConfService.conf) {
        if (
            angular.isUndefined(conf)
            || configurators.indexOf(ConfigurationsService.conf.conf) === -1
        ) {
            return;
        }
        accessories = [];
        sashHardwares = [];
        var sides = ['top', 'bottom', 'left', 'right'];
        var i = 0;

        angular.forEach(allAccessories, function foreachAccessory(accessory) {
            var availableSystemsIds = accessory.window_lines_ids;
            if (confType == 'roller_shutter') {
                if (
                    angular.isDefined(conf.RollerShutter.type.id)
                    && availableSystemsIds.indexOf(
                        String(conf.RollerShutter.type.id)
                    ) > -1
                ) {
                    accessories.push(accessory);
                }
            } else {
                if (
                    confType == 'accessory'
                    || (angular.isDefined(conf.System.id)
                        && availableSystemsIds.indexOf(
                            conf.System.id
                        ) > -1)
                ) {
                    accessories.push(accessory);
                }
            }
            // szukaj akcesorii dla skrzydeł
            if (!angular.isUndefined(conf.Fitting)) {
                if (
                    availableSystemsIds.indexOf(conf.System.id) > -1
                ) {
                    sashHardwares.push(accessory);
                }
            }
        });

        if (accessories.length > 0 || ConfigurationsService.conf.conf === 'garage') {
            StepFactory.enable('accessories');
        } else {
            StepFactory.disable('accessories');
        }

        conf.Accessories = conf.Accessories.filter(
            el => filterAcessory(el, conf)
        );
        if (conf.SideAccessories) {
            for (i = 0; i < sides.length; i++) {
                conf.SideAccessories[
                    sides[i]
                ] = conf.SideAccessories[sides[i]].filter(
                    el => filterAcessory(el, conf)
                );
            }
        }
        if (angular.isDefined(conf.Sashes)) {
            for (i = 0; i < conf.Sashes.length; i++) {
                conf.Sashes[
                    i
                ].hardware = conf.Sashes[i].hardware.filter(
                    el => filterAcessory(el, conf)
                );
            }
        }
        PriceService.count();
        ParametersService.count(conf);
    }

    /**
     * Aktualizacja danych w akcesoriach
     *
     * @param {any} place Miejsce
     * @param {any} accessory Akcesoria
     */
    function updateAccessories(conf = ConfigurationsService.conf.Current) {

        if (conf.type !== 'roller_shutter' && conf.type !== 'garage') {
            ['top', 'bottom'].map(side => {
                if (
                    conf.SideAccessories
                    && conf.SideAccessories[side]
                    && conf.SideAccessories[side].length
                ) {
                    conf.SideAccessories[side].map(accessory => {
                        if (accessory.accessory.type === 'widening') {
                            accessory.amount =
                                conf.Width
                                + conf.SideAccessories.sizes.left
                                + conf.SideAccessories.sizes.right;
                        }
                    });
                }
            });
            if (conf.Accessories && conf.Accessories.length) {
                conf.Accessories.forEach(accessory =>
                    setAmountAccessory(
                        accessory,
                        'configuration',
                        conf,
                        null
                    )
                );
            }
            if (conf.Sashes && conf.Sashes.length) {
                conf.Sashes.forEach(sash =>
                    sash.hardware.forEach(accessory =>
                        setAmountAccessory(
                            accessory,
                            'sash',
                            conf,
                            sash
                        )
                    )
                );
            }
        }
    }

    /**
     * Funkcja filtra akcesoriów
     * @param {object} el Akcesoium
     * @return {boolean}  Czy pasuuje do filtra
     */
    function filterAcessory(el, conf = ConfigurationsService.conf.Current) {
        var availableSystemsIds = el.accessory.window_lines_ids;
        if (CurConfService.conf == 'roller_shutter') {
            if (
                angular.isDefined(ConfigurationsService.conf.Current.RollerShutter.type.id)
                && availableSystemsIds.indexOf(
                    String(ConfigurationsService.conf.Current.RollerShutter.type.id)
                ) > -1
            ) {
                return true;
            }
        } else if (
            CurConfService.conf == 'accessory'
            || (angular.isDefined(conf.System.id)
                && availableSystemsIds.indexOf(conf.System.id) > -1)
            || (conf.hasRoller
                && angular.isDefined(conf.RollerShutter.type.id)
                && availableSystemsIds.indexOf(
                    String(conf.RollerShutter.type.id)
                ) > -1)
        ) {
            return true;
        }
        return false;
    }

    /**
     * Funkcja dodajaca
     * @param {object} place     Miejsce
     * @param {object} accessory Akcesoria
     * @param {object} color     Kolor
     */
    function add(place, accessory, color) {
        let count = ~~accessory.count || 1;
        const colorOptions =
            accessory.show_colors && accessory.price_source === 'table'
                ? accessory.colorOptions
                : null;

        const sides = ['top', 'bottom', 'left', 'right'];
        const category = ConfiguratorsDataService.data.windowAccessoriesCategories.filter(
            e => e.id == accessory.window_accessories_category_id
        );
        const subcategory = ConfiguratorsDataService.data.windowAccessoriesSubCategories.filter(
            e => e.id == accessory.window_accessories_category_id
        );
        // pozycja środka nawiewnika
        let position = null;
        if (accessory.type == 'ventilator' || accessory.type == 'ventilator_hole') {
            position =
                ~~accessory.position || (angular.isObject(place) ? place.rWidth / 2 : 1) || 1;
        }

        // Lista akcesoriow - domyslnie do calego zestawu
        let accessoriesArr = ConfigurationsService.conf.Current.Accessories;

        let sizes = {};
        if (angular.isObject(ConfigurationsService.conf.Current.SideAccessories)) {
            sizes = ConfigurationsService.conf.Current.SideAccessories.sizes;
        }

        // Miejsce docelowa gdzie trafia akcesoria
        let forIt = 'construction';
        if (angular.isObject(place) && angular.isDefined(place.id)) {
            accessoriesArr = place.hardware;
            forIt = 'sash';
        } else if (angular.isString(place) && sides.indexOf(place) > -1) {
            accessoriesArr = ConfigurationsService.conf.Current.SideAccessories[place];
            forIt = 'side';
        }

        /** Można dodać tylko jeden dodatek z tej grupy */
        let accessoriesFromSameCategory =
            angular.isDefined(category[0]) && category[0].only_one_accessory
                ? accessoriesArr.filter(
                      el =>
                          el.accessory.window_accessories_category_id == category[0].id
                          && el.accessory.id != accessory.id
                  )
                : [];

        if (!accessoriesFromSameCategory.length) {
            accessoriesFromSameCategory =
                angular.isDefined(subcategory[0]) && subcategory[0].only_one_accessory
                    ? accessoriesArr.filter(
                          el =>
                              el.accessory.window_accessories_category_id == subcategory[0].id
                              && el.accessory.id != accessory.id
                      )
                    : [];
        }

        /** Można dodać tylko jedną sztukę z tej grupy */
        let accessoryFromSameCategory =
            angular.isDefined(category[0]) && category[0].only_single_accessory
                ? accessoriesArr.filter(
                      el =>
                          el.accessory.window_accessories_category_id == category[0].id
                          && el.accessory.id === accessory.id
                  )
                : [];

        if (!accessoryFromSameCategory.length) {
            accessoryFromSameCategory =
                angular.isDefined(subcategory[0]) && subcategory[0].only_single_accessory
                    ? accessoriesArr.filter(
                          el =>
                              el.accessory.window_accessories_category_id == subcategory[0].id
                              && el.accessory.id === accessory.id
                      )
                    : [];
        }

        if (
            IccConfig.Configurators.oneVentilator
            && ['accessory', 'complementary_goods'].indexOf(ConfigurationsService.conf.conf) == -1
            && ['ventilator', 'ventilator_hole'].includes(accessory.type)
            && accessoriesArr.some(o =>
                ['ventilator', 'ventilator_hole'].includes(o.accessory.type)
            )
        ) {
            $rootScope.showInfo(
                $filter('translate')('ACCESSORY|Można dodać tylko jeden nawiewnik')
            );
        } else if (
            ['accessory', 'complementary_goods'].indexOf(ConfigurationsService.conf.conf) == -1
            && accessoriesFromSameCategory.length
        ) {
            $rootScope.showInfo(
                $filter('translate')(
                    'ACCESSORY|Można dodać tylko jeden dodatek z tej Kategorii. Aktualnie dodany dodatek z tej kategorii to {accessoryName}',
                    { accessoryName: accessoriesFromSameCategory[0].accessory.name }
                )
            );
        } else if (
            ['accessory', 'complementary_goods'].indexOf(ConfigurationsService.conf.conf) == -1
            && accessoryFromSameCategory.length
        ) {
            $rootScope.showInfo(
                $filter('translate')(
                    'ACCESSORY|Można dodać tylko jedną sztukę dodatków z tej Kategorii. Aktualnie dodany dodatek z tej kategorii to {accessoryName}',
                    { accessoryName: accessoryFromSameCategory[0].accessory.name }
                )
            );
        } else {
            if (
                (IccConfig.Configurators.oneVentilator
                    && ['ventilator', 'ventilator_hole'].includes(accessory.type))
                || (category[0] && category[0].only_single_accessory)
                || (subcategory[0] && subcategory[0].only_single_accessory)
            ) {
                count = 1;
            }

            // Wybrany kolor akcesorium
            let colorId = null;
            if (angular.isObject(color) && angular.isDefined(color.id)) {
                colorId = color.id;
            }

            // Wymiar elementu
            let amount = 0;

            // Masa elementu
            let weight = 0;

            // Szerokość
            let width = 0;

            // Wysokość
            let height = 0;

            if (
                ['renson', 'ventilator', 'ventilator_hole', 'windowsill'].includes(accessory.type)
            ) {
                width = parseFloat(accessory.width) || 0;
                height = parseFloat(accessory.height) || 0;
            }

            // cena za sztukę
            if (~~accessory.price_type === 0) {
                amount = 0;
                weight = parseFloat(accessory.weight) * count || 0;
                // cena za m2
            } else if (~~accessory.price_type == 1) {
                width = IccMath.dimToMilimeters(parseFloat(accessory.amount_width));
                height = IccMath.dimToMilimeters(parseFloat(accessory.amount_height));

                if (width && height) {
                    amount = (width * height) / 1000000;
                    weight =
                        ((count * width * height) / 1000000) * parseFloat(accessory.weight) || 0;
                } else {
                    $rootScope.showInfo(
                        $filter('translate')(
                            'CONFIGURATOR|Uzupełnij wszystkie pola przed dodaniem akcesoriów.'
                        ),
                        null
                    );
                    return;
                }
                // cena za mb
            } else {
                const valAmount = IccMath.dimToMilimeters(parseFloat(accessory.amount));

                if (valAmount > 0) {
                    amount = valAmount;
                } else if (forIt == 'side') {
                    amount =
                        place == 'left' || place == 'right'
                            ? ConfigurationsService.conf.Current.Height
                            : ConfigurationsService.conf.Current.Width + sizes.left + sizes.right;
                } else if (forIt == 'sash' && accessory.type == 'kicker') {
                    amount = place.glazingSizes.width;
                } else if (forIt == 'sash' && accessory.type == 'renson') {
                    amount = width =
                        place.intSashes && place.intSashes.length
                            ? place.intSashes
                                  .filter(o => !o.ry)
                                  .map(o => o.glazingSizes.width)
                                  .reduce((a, b) => a + b, 0)
                            : place.glazingSizes.width;
                } else {
                    $rootScope.showInfo(
                        $filter('translate')(
                            'CONFIGURATOR|Uzupełnij wszystkie pola przed dodaniem akcesoriów.'
                        ),
                        null
                    );
                    return;
                }

                weight = (amount / 1000) * parseFloat(accessory.weight) * count || 0;
            }

            // nowe akcesoria
            if (
                !accessoriesArr.some(
                    el =>
                        el.accessory.id == accessory.id
                        && el.amount == amount
                        && ((el.color == colorId && accessory.price_source == 'colors')
                            || (accessory.price_source == 'table'
                                && el.colorOptions == colorOptions)
                            || (accessory.price_source == 'confColors'
                                && accessory.selectedColor
                                && el.accessory.selectedColor
                                && accessory.selectedColor.frame
                                && accessory.selectedColor.frame.core
                                && accessory.selectedColor.frame.inner
                                && accessory.selectedColor.frame.outer
                                && el.accessory.selectedColor.frame
                                && el.accessory.selectedColor.frame.core
                                && el.accessory.selectedColor.frame.inner
                                && el.accessory.selectedColor.frame.outer
                                && el.accessory.selectedColor.frame.core.id
                                    == accessory.selectedColor.frame.core.id
                                && el.accessory.selectedColor.frame.inner.id
                                    == accessory.selectedColor.frame.inner.id
                                && el.accessory.selectedColor.frame.outer.id
                                    == accessory.selectedColor.frame.outer.id))
                )
            ) {
                if (
                    (~~accessory.price_type === 0
                        && count > 0
                        && (accessory.price_source != 'colors'
                            || (angular.isDefined(color) && angular.isDefined(colorId))))
                    || (~~accessory.price_type !== 0
                        && count > 0
                        && (amount > 0 || forIt == 'side')
                        && (accessory.price_source != 'colors'
                            || (angular.isDefined(color) && angular.isDefined(colorId))))
                ) {
                    let newPos = 0;
                    if (forIt === 'side') {
                        sizes[place] += count * accessory.height;

                        if (accessoriesArr.length > 0) {
                            newPos =
                                accessoriesArr[accessoriesArr.length - 1].pos
                                + accessoriesArr[accessoriesArr.length - 1].accessory.height
                                    * accessoriesArr[accessoriesArr.length - 1].count;
                        }
                    }
                    const accessoryClone = Core.copy(accessory);
                    accessoryClone.color = Core.copy(color);
                    delete accessoryClone.count;
                    delete accessoryClone.amount;
                    delete accessoryClone.position;
                    delete accessoryClone.amount_width;
                    delete accessoryClone.amount_height;
                    accessoriesArr.push({
                        count,
                        amount,
                        position,
                        weight,
                        width,
                        height,
                        colorOptions,
                        accessory: accessoryClone,
                        color: angular.isObject(color) ? color.id : null,
                        pos: newPos,
                        side: angular.isDefined(place)
                            ? angular.isDefined(place.id)
                                ? place.id
                                : place
                            : undefined,
                        category: angular.isDefined(category[0])
                            ? category[0].name
                            : angular.isDefined(subcategory[0])
                            ? subcategory[0].name
                            : null,
                    });
                }
                // zwiększenei ilości
            } else {
                for (let i = 0; i < accessoriesArr.length; i++) {
                    if (
                        accessoriesArr[i].accessory.id == accessory.id
                        && accessoriesArr[i].amount == amount
                        && ((accessoriesArr[i].color == colorId
                            && accessoriesArr[i].accessory.price_source == 'colors')
                            || (accessoriesArr[i].accessory.price_source == 'table'
                                && accessoriesArr[i].colorOptions == colorOptions)
                            || (accessoriesArr[i].accessory.price_source == 'confColors'
                                && accessory.selectedColor
                                && accessoriesArr[i].accessory.selectedColor
                                && accessory.selectedColor.frame
                                && accessory.selectedColor.frame.core
                                && accessory.selectedColor.frame.inner
                                && accessory.selectedColor.frame.outer
                                && accessoriesArr[i].accessory.selectedColor.frame
                                && accessoriesArr[i].accessory.selectedColor.frame.core
                                && accessoriesArr[i].accessory.selectedColor.frame.inner
                                && accessoriesArr[i].accessory.selectedColor.frame.outer
                                && accessoriesArr[i].accessory.selectedColor.frame.core.id
                                    == accessory.selectedColor.frame.core.id
                                && accessoriesArr[i].accessory.selectedColor.frame.inner.id
                                    == accessory.selectedColor.frame.inner.id
                                && accessoriesArr[i].accessory.selectedColor.frame.outer.id
                                    == accessory.selectedColor.frame.outer.id))
                    ) {
                        accessoriesArr[i].count += count;
                        accessoriesArr[i].weight += weight;
                        if (forIt === 'side') {
                            sizes[place] += count * accessory.height;
                            if (i < accessoriesArr.length - 1) {
                                accessoriesArr[i + 1].pos += count * accessory.height;
                            }
                        }
                    }
                    if (forIt === 'side') {
                        if (i === 0) {
                            accessoriesArr[i].pos = 0;
                        } else {
                            accessoriesArr[i].pos =
                                accessoriesArr[i - 1].accessory.height
                                    * accessoriesArr[i - 1].count
                                + accessoriesArr[i - 1].pos;
                        }
                    }
                }
            }
        }

        accessory.count = getCountAccessory(
            accessory,
            forIt,
            ConfigurationsService.conf.Current,
            forIt === 'sash' ? place : null
        );
        updateAccessories();
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    function removeAllFromSide(place) {
        var sides = ['top', 'bottom', 'left', 'right'];

        if (sides.indexOf(place) === -1) {
            return;
        }

        ConfigurationsService.conf.Current.SideAccessories.sizes[place] = 0;
        ConfigurationsService.conf.Current.SideAccessories[place] = [];
    }

    /**
     * Funkcja sprawdzajaca czy i ktory typ nawiewnika wybrano
     * @param  {object} sashNumber  Numer skrzydła
     */
    function selectedVentilatorType(sashNumber) {
        let ventilatorOption = null;
        if (ConfigurationsService.conf.Current.Sashes) {
            for (let i = 0; i < ConfigurationsService.conf.Current.Sashes.length; i++) {
                if (ConfigurationsService.conf.Current.Sashes[i].index == sashNumber) {
                    for (
                        let j = 0;
                        j < ConfigurationsService.conf.Current.Sashes[i].hardware.length;
                        j++
                    ) {
                        switch (
                            ConfigurationsService.conf.Current.Sashes[i].hardware[j].accessory.type
                        ) {
                            case 'ventilator_hole':
                                ventilatorOption = 'ventilator_hole';
                                break;
                            case 'ventilator':
                                ventilatorOption = 'ventilator';
                                break;
                            default:
                                ventilatorOption = null;
                        }
                    }
                }
            }
        }
        return ventilatorOption;
    }

    /**
     * Funkcja usuwajaca
     * @param  {object} place     Miejsce
     * @param  {object} accessory Akcesoria
     */
    function remove(place, accessory) {
        // debugger;
        var newAccessoriesArr;
        var sides = ['top', 'bottom', 'left', 'right'];
        if (angular.isObject(place) && angular.isDefined(place.id)) {
            newAccessoriesArr = place.hardware.filter(function filterHardware(el) {
                return (
                    String(el.amount) !== String(accessory.amount)
                    || el.accessory.id != accessory.accessory.id
                    || (el.color != accessory.color
                        && accessory.accessory.price_source == 'colors')
                    || el.colorOptions != accessory.colorOptions
                );
            });
            place.hardware = newAccessoriesArr;
        } else if (angular.isString(place) && sides.indexOf(place) > -1) {
            newAccessoriesArr = ConfigurationsService.conf.Current.SideAccessories[place].filter(
                function filterSideAcc(el) {
                    return (
                        String(el.amount) !== String(accessory.amount)
                        || el.accessory.id != accessory.accessory.id
                        || (el.color != accessory.color
                            && accessory.accessory.price_source == 'colors')
                        || el.colorOptions != accessory.colorOptions
                    );
                }
            );
            ConfigurationsService.conf.Current.SideAccessories.sizes[place] -=
                accessory.count * accessory.accessory.height;

            ConfigurationsService.conf.Current.SideAccessories[place] = newAccessoriesArr;
        } else {
            newAccessoriesArr = ConfigurationsService.conf.Current.Accessories.filter(
                function filterAccessories(el) {
                    return (
                        String(el.amount) !== String(accessory.amount)
                        || el.accessory.id != accessory.accessory.id
                        || (el.color != accessory.color
                            && accessory.accessory.price_source == 'colors')
                        || el.colorOptions != accessory.colorOptions
                    );
                }
            );
            ConfigurationsService.conf.Current.Accessories = newAccessoriesArr;
        }
        if (angular.isObject(place)) {
            selectedVentilatorType(place.index);
        }
        updateAccessories();

        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);

        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });

        ScrollbarService.update();
        if (IccConfig.Configurators.dependencies) {
            EventBusService.post({ key: 'processDependencies', value: null });
        }
    }

    /**
     * Funkcja odświeżajaca cenę
     */
    function refreshPriceProp() {
        priceProp = PriceAccessoryService.getAccessoryPriceField(
            ConfigurationsService.conf.Current.Colors,
            ConfigurationsService.conf.Current.type
        );
    }

    /**
     * Funkcja otwierajaca modal z akcesoriami
     * @param  {object} sash       Skrzydło
     * @param  {object} sashNumber Numer skrzydła
     */
    function openModalAccessories(sash, sashNumber, confType) {
        findAccessories(ConfigurationsService.conf.Current, confType || CurConfService.conf);
        var sides = ['top', 'bottom', 'left', 'right'];
        var forIt = 'configuration';
        var sideIdx = '0';
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'accessories',
            });
        }

        if (angular.isDefined(sash) && angular.isDefined(sash.id)) {
            forIt = 'sash';
        } else if (angular.isString(sash) && sides.indexOf(sash) > -1) {
            forIt = 'side';

            if (sash === 'top') {
                sideIdx = '2';
            } else if (sash === 'bottom') {
                sideIdx = '3';
            } else if (sash === 'left') {
                sideIdx = '0';
            } else {
                sideIdx = '1';
            }
        } else if (confType === 'roller_shutter') {
            forIt = 'roller_shutter';
        }

        if (forIt == 'side' && sash == 'top' && ConfigurationsService.conf.Current.hasRoller) {
            return;
        }

        var hardwares = [];
        if (angular.isObject(sash)) {
            hardwares = sashHardwares.filter(el => {
                var availableSashTypesIds = el.sash_types_ids;
                if (!angular.isUndefined(sash.type) && !angular.isUndefined(sash.type.id)) {
                    // sprawdź czy dodatek należy do 'sash'
                    if (
                        el.place.indexOf('sash') > -1
                        && availableSashTypesIds.indexOf(sash.type.id) > -1
                    ) {
                        return true;
                    }
                }
                return false;
            });
        }

        var sideAccessories = accessories.filter(
            el => el.place_side.indexOf(sideIdx) !== -1 && el.place.indexOf('side') > -1
        );

        var modalAccessories = accessories;
        if (CurConfService.conf !== 'accessory') {
            switch (forIt) {
                case 'configuration':
                case 'roller_shutter':
                    modalAccessories = accessories.filter(el => el.place.indexOf('conf') > -1);
                    break;
                case 'sash':
                    modalAccessories = hardwares;
                    break;
                case 'side':
                    modalAccessories = sideAccessories;
                    break;
            }
            modalAccessories = modalAccessories.map(accessory =>
                setAmountAccessory(
                    accessory,
                    forIt,
                    ConfigurationsService.conf.Current,
                    forIt === 'sash' ? sash : null
                )
            );
        } else {
            modalAccessories = accessories.filter(access => {
                const category = ConfiguratorsDataService.data.windowAccessoriesCategories.filter(
                    el => el.id == access.window_accessories_category_id
                );
                return access.access_conf || !category.show;
            });
        }

        var categories = ConfiguratorsDataService.data.windowAccessoriesCategories.filter(
            el => accessoriesFilter(modalAccessories, el.id).length > 0
        );

        var subcategoriesArr = subcategories.filter(
            el => accessoriesFilter(modalAccessories, el.parent_id, el.id).length > 0
        );

        var modalInstance = $uibModal.open({
            templateUrl: 'modalAccessories.html',
            controller: 'ModalAccessoriesCtrl as maccessory',
            resolve: {
                forIt() {
                    return forIt;
                },
                accessories() {
                    return modalAccessories.map(el => {
                        if (el.price_source == 'confColors') {
                            setDefaultColors(el);
                        }
                        if (!el.blockAmountChange) {
                            el.amount = null;
                        }
                        return el;
                    });
                },
                categories() {
                    return categories;
                },
                subcategories() {
                    return subcategoriesArr.map(el => {
                        el.id *= 1;
                        el.parent_id *= 1;
                        return el;
                    });
                },
                sash() {
                    return sash;
                },
                sashNum() {
                    return sashNumber;
                },
                colors() {
                    return factory.colorsAll;
                },
                noPrice() {
                    return ConfigurationsService.price.noPrice;
                },
            },
        });

        modalInstance.result.then(() => {
            ScrollbarService.update();
        });

        // odśwież
        modalInstance.result.finally(() => {
            if (IccConfig.Configurators.dependencies) {
                EventBusService.post({ key: 'processDependencies', value: null });
                PriceService.count();
            }
            EventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
            TimeLimitService.count();
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    /**
     * Funkcja liczaca akcesoria (w górę)
     * @param  {object} accessory Akcesoria
     */
    function countUp(accessory) {
        ConfigurationsService.conf.Current.SideAccessories.sizes[accessory.side] += ~~accessory
            .accessory.height;
        accessory.count++;

        updateAccessories();
        PriceService.count();

        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Funkcja liczaca akcesoria (w dół)
     * @param  {object} accessory Akcesoria
     */
    function countDown(accessory) {
        if (accessory.count > 1) {
            accessory.count--;
            ConfigurationsService.conf.Current.SideAccessories.sizes[accessory.side] -= ~~accessory
                .accessory.height;
        }

        updateAccessories();
        PriceService.count();

        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Przelicz cenę
     */
    function countPrice() {
        PriceService.count();
    }

    /**
     * Otwieranie obrazka modala dóbr komplementarych
     * @param  {Object} good Dobro
     */
    function openModalColors(accessory, type = 'accessory') {
        const material = accessory.material;
        $uibModal
            .open({
                templateUrl: colorsModalTemplate,
                controller: 'ModalColorsCtrl as $ctrl',
                resolve: {
                    accessory: () => accessory,
                    type: () => type,
                    material: () => material,
                },
            })
            .result.then(selection => {
                accessory.selectedColor = Core.copy(selection.colors);
                accessory.selectedWood = Core.copy(selection.wood);
            });
    }

    /**
     * Ustawia domyślne kolory dodatku
     *
     * @param {any} accessory Dodatek
     */
    function setDefaultColors(accessory) {
        const config = {
            Colors: accessory.selectedColor || {
                frame: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {},
                },
                sash: {
                    core: {},
                    outer: {},
                    inner: {},
                    alushell: {},
                },
            },
            HasAlushell: false,
            AlushellType: '',
            System: { type: accessory.material },
            ColorType: 'White',
            Wood: accessory.selectedWood || {},
            ColorsSashExt: false,
        };

        const configDefault = Core.copy(config);
        ColorsFactory.loadData();
        ColorsFactory.loadColorsBySystem(
            color =>
                accessory.conf_colors_ids.map(Number).indexOf(~~color.id) > -1
                || (color.groups
                    && accessory.conf_color_groups_ids
                        .map(Number)
                        .some(c => color.groups.map(Number).indexOf(~~c) > -1))
        );

        ColorsFactory.setDefaultColorTypeForSystem(config, configDefault);
        ColorsFactory.setDefaultWood(config, configDefault);
        ColorsFactory.loadColorsByWood(config);
        ColorsFactory.setDefaultColors(false, config, configDefault);
        ColorsFactory.setDefaultColorTypeForColors(config, configDefault);
        accessory.selectedColor = Core.copy(config.Colors);
        accessory.selectedWood = Core.copy(config.Wood);
    }

    /**
     * Funkcja liczaca otwory
     * @param  {object} sash Skrzydło
     * @param  {object} side Strona
     */
    function getCountHoles(sash, side) {
        var length = sash.rWidth;
        if (side == 'left' || side == 'right') {
            length = sash.rHeight;
        }
        var fromEdge = dowelHoleParams.fromEdge;
        var betweenO = dowelHoleParams.between;
        if (length - 2 * fromEdge > 0) {
            return Math.ceil((length - 2 * fromEdge) / betweenO) + 1;
        } else {
            return 0;
        }
    }

    /**
     * Funkcja wyznaczajaca pozycje otworów pod dyble
     * @param  {object} sash Skrzydło
     * @param  {object} side Strona
     * @param  {object} n    N
     */
    function placeDowelHole(sash, side, n) {
        var length = sash.rWidth;
        if (side == 'left' || side == 'right') {
            length = sash.rHeight;
        }
        var fromEdge = dowelHoleParams.fromEdge;
        var countSide = getCountHoles(sash, side) - 1;
        var between = 0;
        if (countSide > 0) {
            between = (length - 2 * fromEdge) / countSide;
        }

        return fromEdge + n * between;
    }

    function setAmountAccessory(accessory, forIt, conf, sash = null) {
        let accessoryData = accessory;
        if (accessory.accessory) {
            accessoryData = accessory.accessory;
        }
        let amount;
        if (forIt === 'sash') {
            amount = calcAmountFromFormula(accessoryData.amount_sash_formula, conf, sash);
        } else if (forIt === 'configuration' || forIt === 'roller_shutter') {
            amount = calcAmountFromFormula(accessoryData.amount_conf_formula, conf, sash);
        }
        accessory.count = accessory.count || 1;
        if (amount) {
            if (accessoryData.price_type == 2) {
                accessory.amount = amount;
                accessory.blockAmountChange = true;
            } else if (accessoryData.price_type == 0) {
                accessory.count = amount;
                accessory.blockCountChange = true;
            }
        }
        return accessory;
    }

    function getCountAccessory(accessory, forIt, conf, sash = null) {
        let amount;
        if (forIt === 'sash') {
            amount = calcAmountFromFormula(accessory.amount_sash_formula, conf, sash);
        } else if (forIt === 'configuration') {
            amount = calcAmountFromFormula(accessory.amount_conf_formula, conf, sash);
        }
        if (amount && accessory.price_type == 0) {
            return amount;
        }
        return 1;
    }

    function calcAmountFromFormula(formula, conf, sash = null) {
        if (formula) {
            let value = 0;
            formula = Core.parseJson(formula);
            let symbol = 'plus';
            formula.forEach(block => {
                if (['plus', 'minus'].indexOf(block.type) > -1) {
                    symbol = block.type;
                } else {
                    let valueToAdd = 0;
                    switch (block.type) {
                        case 'number':
                            valueToAdd = Number(block.value);
                            break;
                        case 'frameWidth':
                            valueToAdd = conf.Width;
                            break;
                        case 'frameHeight':
                            valueToAdd = conf.Height;
                            break;
                        case 'frameLightOuterWidth':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    s => s.sashId == sash.id
                                );
                                const frame = conf.Frames.find(f => f.id === sash.frameId);
                                const frameSides = ProfilesService.getFrameSides(frame, conf);
                                if (sashDimensions) {
                                    valueToAdd = ['left', 'right'].reduce((val, side) => {
                                        const profile = conf.UsedProfiles.find(
                                            p =>
                                                p.id
                                                === (sash.nearMullions[side] !== -1
                                                    ? conf.Mullions.find(
                                                          m => m.id === sash.nearMullions[side]
                                                      ).profileId
                                                    : frame.frame[
                                                          frameSides.findIndex(s => s.side === side)
                                                      ].profileId)
                                        );
                                        const profileRatio = sash.nearMullions[side] !== -1 ? 2 : 1;
                                        return profile
                                            ? val
                                                  + profile.width / profileRatio
                                                  - profile.widthOut / profileRatio
                                            : 0;
                                    }, sashDimensions.inner.rect.width);
                                }
                            }
                            break;
                        case 'frameLightOuterHeight':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    s => s.sashId == sash.id
                                );
                                const frame = conf.Frames.find(f => f.id === sash.frameId);
                                const frameSides = ProfilesService.getFrameSides(frame, conf);
                                if (sashDimensions) {
                                    valueToAdd = ['top', 'bottom'].reduce((val, side) => {
                                        const profile = conf.UsedProfiles.find(
                                            p =>
                                                p.id
                                                === (sash.nearMullions[side] !== -1
                                                    ? conf.Mullions.find(
                                                          m => m.id === sash.nearMullions[side]
                                                      ).profileId
                                                    : frame.frame[
                                                          frameSides.findIndex(s => s.side === side)
                                                      ].profileId)
                                        );
                                        const profileRatio = sash.nearMullions[side] !== -1 ? 2 : 1;
                                        return profile
                                            ? val
                                                  + profile.width / profileRatio
                                                  - profile.widthOut / profileRatio
                                            : 0;
                                    }, sashDimensions.inner.rect.height);
                                }
                            }
                            break;
                        case 'frameLightWidth':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    s => s.sashId == sash.id
                                );
                                if (sashDimensions) {
                                    valueToAdd = sashDimensions.inner.rect.width;
                                }
                            }
                            break;
                        case 'frameLightHeight':
                            if (sash) {
                                const sashDimensions = conf.drawData.sash.find(
                                    s => s.sashId == sash.id
                                );
                                if (sashDimensions) {
                                    valueToAdd = sashDimensions.inner.rect.height;
                                }
                            }
                            break;
                        case 'sashWidth':
                            if (sash) {
                                const sashFrameDimensions = conf.drawData.sashFrame.find(
                                    s => s.sashId == sash.id
                                );
                                if (sashFrameDimensions) {
                                    valueToAdd = sashFrameDimensions.outer.rect.width;
                                }
                            }
                            break;
                        case 'sashHeight':
                            if (sash) {
                                const sashFrameDimensions = conf.drawData.sashFrame.find(
                                    s => s.sashId == sash.id
                                );
                                if (sashFrameDimensions) {
                                    valueToAdd = sashFrameDimensions.outer.rect.height;
                                }
                            }
                            break;
                        case 'chamberGlazing':
                            if (sash) {
                                valueToAdd = Number(sash.glazing.glazing_count)
                                    ? Number(sash.glazing.glazing_count) - 1
                                    : 0;
                            }
                            break;
                    }
                    if (symbol === 'minus') {
                        value -= valueToAdd;
                    } else {
                        value += valueToAdd;
                    }
                }
            });
            return value;
        }
        return 0;
    }

    function getAccessory(id) {
        return Core.copy(allAccessories.find(el => el.id == id));
    }
}
